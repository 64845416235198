import React, { Component } from "react";
import { Link } from "react-router-dom";

class EventListItem extends Component {
  render() {
    return (
      <li>
        <Link to="/news-and-events">
          <img src={`/images/service-types/${this.props.serviceType}.png`} width="48" height="48" alt="" />
          <div className="list-item-detail">
            <h3 className="list-item-title" dangerouslySetInnerHTML={{ __html: this.props.name }}></h3>
            <span className="list-item-meta">
              <span>
                <i className="fa fa-calendar" />
                {this.props.date}
              </span>
            </span>
          </div>
        </Link>
      </li>
    );
  }
}

export default EventListItem;
