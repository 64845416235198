import {Component} from "react";

import PageTitle from "../components/page_title/PageTitle"
import CornerstonesPage from "./CornerstonesPage";
// import ToddlerGroupPage from "./ToddlerGroupPage";
import GirlguidingPage from "./GirlguidingPage";

import DBSCheckLogoImage from "./SundayClubsPage/dbs_check-logo.jpg"
import DBSCheckLogoImage2x from "./SundayClubsPage/dbs_check-logo@2x.jpg"

class SundayClubsPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <section id="sunday_clubs-page">
                <PageTitle text="Children's Clubs"/>

                <div className="fullwidth-block">
                    <article className="container">
                        <h1>Sunday Clubs</h1>
                        <p>
                            Our large and thriving Sunday School is split into four age groups. The Sunday Clubs are
                            called to leave the Sunday service after the Collect, when they are led to the Halls for
                            age-appropriate educational activities. The Clubs re-join the Service at the moment of
                            Communion, and they are usually given the opportunity at the end of the service to share
                            what they have learned and what they have been doing (often with a cheerful song to round
                            off our morning on an uplifting note!).
                        </p>
                        <dl>
                            <dt>Pebbles</dt>
                            <dd>For those who were born after 1 September 2018</dd>
                            <dt>Rocks</dt>
                            <dd>For those who are in a School Nursery or Reception Class (ie. born before 1 September
                                2018)
                            </dd>
                            <dt>Boulders</dt>
                            <dd>For those in School years 1 &amp; 2</dd>
                            <dt>Cornerstones</dt>
                            <dd>For those in school years 3 to 6</dd>
                        </dl>

                        <CornerstonesPage/>

                        {/*<ToddlerGroupPage/>*/}

                        <GirlguidingPage/>

                        <hr/>
                        <p>
                            <img src={DBSCheckLogoImage}
                                 srcSet={DBSCheckLogoImage + ' 1x,' + DBSCheckLogoImage2x + ' 2x'} alt="" width="64"
                                 height="35" className="dbs-check"/>
                            All our leaders are fully trained and DBS checked
                        </p>
                    </article>
                </div>
            </section>
        );
    }
}

export default SundayClubsPage;
