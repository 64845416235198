import {Component} from "react";

import PageTitle from "../components/page_title/PageTitle";

import ShortBio from "../components/bio/ShortBio";

class CommunityAndOutreachPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <section id="community-and-outreach">
                <PageTitle text="Community &amp; Outreach"/>

                <div className="fullwidth-block">
                    <article className="container">
                        <div className="row">
                            <p>
                                This group works hard to organize Social and Fundraising activities throughout the year.
                                New members (and ideas!) are always welcome.
                            </p>
                        </div>
                        <ShortBio
                            name="Cate Brooks"
                            shortName="Cate"
                            role="Community &amp; Outreach Contact"
                            description="For any enquiries about helping out, please contact Cate for more information."
                            slug="cate_brooks"
                        />
                    </article>
                </div>
            </section>
        );
    }
}

export default CommunityAndOutreachPage;
