import { Component } from "react";

import { Link } from "react-router-dom";
import EventList from "../data/EventList";
import { TwitterTimelineEmbed } from "react-twitter-embed";
import Hero from "../components/hero/Hero";

import "./WelcomePage.css";

import QRCodeImage from "./WelcomePage/qr_code.jpg";
import QRCodeImage2x from "./WelcomePage/qr_code@2x.jpg";

// import QuizNightBlock from "../blocks/QuizNightBlock";

// import DECBlock from "../blocks/DECBlock";
// import RemembranceSundayBlock from "../blocks/RemembranceSundayBlock";

class WelcomePage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <section id="welcome-page">
        <Hero />

        <div className="fullwidth-block welcome-body">
          <div className="fullwidth-block parent row m-0 p-0">{/* <QuizNightBlock /> */}</div>
          <div className="fullwidth-block">
            <div className="container">
              <div className="row">
                <div className="col-md-6 pt-3">
                  <h2 className="section-title">Upcoming Events</h2>
                  <EventList />
                  <div className="text-center">
                    <Link to="/news-and-events" className="button">
                      See all events
                    </Link>
                  </div>
                </div>
                <div className="col-md-6 pt-3">
                  <h2 className="section-title">Latest Tweets</h2>
                  <TwitterTimelineEmbed sourceType="profile" screenName="stmichaelsgh" options={{ height: 600 }} />
                </div>
              </div>
            </div>
          </div>
          <div className="fullwidth-block parent row m-0 p-0">
            {/* <DECBlock />
            <RemembranceSundayBlock /> */}
          </div>
          {/*<div className="fullwidth-block parent row m-0 p-0">*/}
          {/*    <NightingaleNightHikeBlock/>*/}
          {/*</div>*/}
          <div className="fullwidth-block point-of-interest">
            <div className="container">
              <div className="row">
                <div className="col-md-8">
                  <div className="p-5">
                    <h2>Get involved!</h2>
                    <p>
                      There are many ways you can help our at St Michael's, whether it be through donations (either direct or through passive giving)
                      or if you want to get more involved there are many things you can do. Take a look around our website to find out how you can be
                      an important part of our community by offering your time, or your friendship to our happy church.
                    </p>
                    <ul>
                      <li>Sign up to Easyfundraising</li>
                      <li>Help out as part of our Serving team</li>
                      <li>Volunteer to help out with the Cornerstones Youth Group</li>
                      <li>Sing in the Choir</li>
                      <li>Sign up to Amazon Smile</li>
                      <li>Come to our services and be part of our community</li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-4 text-center pt-5">
                  <img src={QRCodeImage} srcSet={QRCodeImage + " 1x," + QRCodeImage2x + " 2x"} alt="QR Code" width="300" height="300" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default WelcomePage;
