import {Component} from "react";

import PageTitle from "../components/page_title/PageTitle";

class FundraisingPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <section id="fundraising">
                <PageTitle text="Fundraising"/>

                <div className="fullwidth-block">
                    <article className="container">
                        <h2>2022 Budget Projection</h2>
                        <p className="text-center">
                            <img src="/images/fundraising/2022-04_april_thermometer.jpg"
                                 alt="Thermometer visualisation in three sections: Red - Propose Common Fund contribution aligned to projected halls income (income £30k , expenditure £30k); Amber - Variable expenses (income £69k, expenditure £72k); Green - £3k shortfall / 12 = £250 per month."
                                 width="266" height="418"/>
                        </p>
                        <div className="row">
                            <div className="col-md-6">
                                <h3>Income</h3>
                                <p>
                                    Assumptions reflect an increase of 17% for 2022 based upon 2021 actual income.
                                </p>
                                <p>
                                    Significant income categories include:
                                </p>
                                <ul className="pl-3">
                                    <li>Halls rental</li>
                                    <li>Donations/Collection</li>
                                    <li>Gift aid receipts</li>
                                    <li>Planned giving/standing orders</li>
                                    <li>Summer fayre</li>
                                </ul>
                            </div>
                            <div className="col-md-6">
                                <h3>Expenditure</h3>
                                <p>
                                    Assumptions reflect a 30% increase for 2022 based upon 2021 actual expenditure.
                                </p>
                                <p>
                                    Significant expense categories include:
                                </p>
                                <ul className="pl-3">
                                    <li>Common fund</li>
                                    <li>Staff (cleaner, halls, organist, youth worker)</li>
                                    <li>Energy &amp; utilities</li>
                                    <li>Fees</li>
                                    <li>Loans</li>
                                    <li>Fire Extinguishers</li>
                                    <li>Heating Maintenance</li>
                                </ul>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        );
    }
}

export default FundraisingPage;
