import {Component} from "react";

import PageTitle from "../components/page_title/PageTitle"

import SundayEucharistServiceImage from "./WorshipPage/sunday_eucharist_service-tile.jpg";
import SundayEucharistServiceImage2x from "./WorshipPage/sunday_eucharist_service-tile@2x.jpg";
import SundayEvensongServiceImage from "./WorshipPage/sunday_evensong_service-tile.jpg";
import SundayEvensongServiceImage2x from "./WorshipPage/sunday_evensong_service-tile@2x.jpg";
import MidWeekEucharistServiceImage from "./WorshipPage/midweek_eucharist_service-tile.jpg";
import MidWeekEucharistServiceImage2x from "./WorshipPage/midweek_eucharist_service-tile@2x.jpg";

import "./WorshipPage.css"

class WorshipPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <section id="services-and-worship">
                <PageTitle text="Services &amp; Worship"/>

                <div className="fullwidth-block">
                    <article className="container">
                        <h2>Worship</h2>
                        <p>
                            At St Michael's we worship in a "central" style; we like to keep a happy mix of formal and
                            informal. Each of our services is open to all comers of all ages, so please feel free to
                            join us at whichever service is most convenient for you!
                        </p>
                        <h2>Service Times</h2>
                        <p>
                            We hold three regular services each week: two on a Sunday (morning and evening), and one
                            mid-week service on Wednesday mornings:
                        </p>
                        <ul className="service-details">
                            <li className="row">
                                <div className="col-md-3">
                                    <img src={SundayEucharistServiceImage}
                                         srcSet={SundayEucharistServiceImage + ' 1x,' + SundayEucharistServiceImage2x + ' 2x'}
                                         width="250" height="" alt=""/>
                                </div>
                                <div className="col-md-9">
                                    <h4>Main Parish Eucharist Service</h4>
                                    <h5>Sunday mornings at 10a.m.</h5>
                                    <p>
                                        We enjoy our main worship of the week in the form of a full, modern-language,
                                        Eucharist with hymns and a sermon.
                                    </p>
                                    <p>
                                        For the children, we have an active Junior Church formed of several
                                        age-appropriate "Sunday Clubs" which the children can attend after the Collect
                                        until the point of Communion.
                                    </p>
                                    <p>
                                        On festivals this service becomes an all-age Eucharist, when our Rainbows,
                                        Brownies and Guide Units often parade.
                                    </p>
                                    <p>
                                        After the service we gather in the hall for tea, coffee, juice, and biscuits for
                                        a time of fellowship and catching up with each other.
                                    </p>
                                    <div className="tags">
                                        <span className="tag">eucharist</span>
                                        <span className="tag">sunday clubs</span>
                                        <span className="tag">singing/hymns</span>
                                        <span className="tag">organ music</span>
                                        <span className="tag">social</span>
                                        <span className="tag">refreshments</span>
                                    </div>
                                </div>
                            </li>
                            <li className="row">
                                <div className="col-md-3">
                                    <img src={SundayEvensongServiceImage}
                                         srcSet={SundayEvensongServiceImage + ' 1x,' + SundayEvensongServiceImage2x + ' 2x'}
                                         width="250" height="" alt=""/>
                                </div>
                                <div className="col-md-9">
                                    <h4>Evensong</h4>
                                    <h5>Sunday evenings at 6p.m.</h5>
                                    <p>
                                        The Evensong is an informal service - an oasis of peace and calm at the end of
                                        the day to round off the week.
                                    </p>
                                    <p>
                                        This service can take a number of different forms suited to the season, and to
                                        the moment.
                                    </p>
                                    {/* <div className="alert alert-warning">
                                    Please note there will be no Mid-week Eucharist on Wednesday, 9 February.
                                </div> */}
                                    <div className="tags">
                                        <span className="tag">evensong</span>
                                        <span className="tag">relaxation</span>
                                        <span className="tag">reflection</span>
                                    </div>
                                </div>
                            </li>
                            <li className="row">
                                <div className="col-md-3">
                                    <img src={MidWeekEucharistServiceImage}
                                         srcSet={MidWeekEucharistServiceImage + ' 1x,' + MidWeekEucharistServiceImage2x + ' 2x'}
                                         width="250" height="" alt=""/>
                                </div>
                                <div className="col-md-9">
                                    <h4>Mid-week Eucharist Service</h4>
                                    <h5>Wednesday mornings at 10a.m.</h5>
                                    <p>
                                        Our Wednesday service is a short, spoken Eucharist (following the Book of Common
                                        Prayer). There are no hymns, and no sermon during this service.
                                    </p>
                                    <p>
                                        At the end of the service, we usually take a half-hour to enjoy tea, coffee,
                                        biscuits (usually chocolate!), and fellowship.
                                    </p>
                                    <div className="tags">
                                        <span className="tag">eucharist</span>
                                        <span className="tag">book of common prayer</span>
                                        <span className="tag">social</span>
                                        <span className="tag">refreshments</span>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </article>
                </div>
            </section>
        );
    }
}

export default WorshipPage;
