import {Component} from "react";

import PageTitle from "../components/page_title/PageTitle"
import ShortBio from "../components/bio/ShortBio";

import CelebrationHallImage from "./VenuesPage/celebration_hall.jpg";
import CelebrationHallKitchenImage from "./VenuesPage/celebration_hall_kitchen.jpg";
import CelebrationHallImage2x from "./VenuesPage/celebration_hall@2x.jpg";
import CelebrationHallKitchenImage2x from "./VenuesPage/celebration_hall_kitchen@2x.jpg";

import GabrielHall1Image from "./VenuesPage/gabriel_hall_1.jpg";
import GabrielHall2Image from "./VenuesPage/gabriel_hall_2.jpg";
import GabrielHall1Image2x from "./VenuesPage/gabriel_hall_1@2x.jpg";
import GabrielHall2Image2x from "./VenuesPage/gabriel_hall_2@2x.jpg";

import FrankBurtonHallImage from "./VenuesPage/frank_burton_hall.jpg";
import FrankBurtonHallImage2x from "./VenuesPage/frank_burton_hall@2x.jpg";

import "./VenuesPage.css"

class VenuesPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <section id="venues-and-hall-hire">
                <PageTitle text="Venues &amp; Hall Hire"/>

                <div className="fullwidth-block">
                    <article className="container">
                        <h2>Our halls</h2>
                        <p>
                            We have five community halls and a self contained suite to rent out either for a one-off
                            event or an ongoing series of meetings/activities.
                        </p>
                        <div className="venue-details">
                            <h3>Celebration Hall</h3>
                            <small>15m x 7m</small>
                            <div>
                                <img src={CelebrationHallImage}
                                     srcSet={CelebrationHallImage + ' 1x,' + CelebrationHallImage2x + ' 2x'} width="300"
                                     height="200" alt=""/>
                                &nbsp;
                                <img src={CelebrationHallKitchenImage}
                                     srcSet={CelebrationHallKitchenImage + ' 1x,' + CelebrationHallKitchenImage2x + ' 2x'}
                                     width="300" height="200" alt=""/>
                            </div>
                            <div className="tags">
                                <span className="tag">kitchen</span>
                                {/*<span className="tag">stage</span>*/}
                                <span className="tag">toilet facilities</span>
                                <span className="tag">disabled access</span>
                            </div>
                        </div>
                        <div className="venue-details">
                            <h3>Gabriel Hall</h3>
                            <small>12m x 8m</small>
                            <div>
                                <img src={GabrielHall1Image}
                                     srcSet={GabrielHall1Image + ' 1x,' + GabrielHall1Image2x + ' 2x'} width="300"
                                     height="200" alt=""/>
                                &nbsp;
                                <img src={GabrielHall2Image}
                                     srcSet={GabrielHall2Image + ' 1x,' + GabrielHall2Image2x + ' 2x'} width="300"
                                     height="200" alt=""/>
                            </div>
                            <div className="tags">
                                <span className="tag">kitchen</span>
                                <span className="tag">toilet facilities</span>
                                <span className="tag">disabled access</span>
                            </div>
                        </div>
                        <div className="venue-details">
                            <h3>Frank Burton Hall</h3>
                            <small>8.5m x 5m</small>
                            <div>
                                <img src={FrankBurtonHallImage}
                                     srcSet={FrankBurtonHallImage + ' 1x,' + FrankBurtonHallImage2x + ' 2x'} width="300"
                                     height="200" alt=""/>
                            </div>
                            <div className="tags">
                                <span className="tag">kettle</span>
                                <span className="tag">hot/cold water</span>
                                <span className="tag">toilet facilities</span>
                                <span className="tag">disabled access</span>
                            </div>
                        </div>
                        <div className="venue-details">
                            <h3>Frank Burton Room</h3>
                            <small>6m x 5m</small>
                            <div className="tags">
                                <span className="tag">small integral kitchen</span>
                                <span className="tag">toilet facilities</span>
                                <span className="tag">disabled access</span>
                            </div>
                        </div>
                        <div className="venue-details">
                            <h3>Angels Rest</h3>
                            <small>6.5m x 5.5m</small>
                            <div className="tags">
                                <span className="tag">kettle</span>
                                <span className="tag">hot/cold water</span>
                                <span className="tag">toilet facilities</span>
                                <span className="tag">disabled access</span>
                            </div>
                        </div>
                        <div className="venue-details">
                            <h3>The Raphael Suite</h3>
                            <p>
                                A unit of 2 small rooms and kitchenette.
                            </p>
                            <p className="font-weight-bold">
                                Please note there is no disabled access to the Raphael Suite.
                            </p>
                            <div className="tags">
                                <span className="tag">kitchenette</span>
                                <span className="tag">toilet facilities</span>
                            </div>
                        </div>
                        <ShortBio
                            name="Peter Newbury"
                            shortName="Peter"
                            role="Halls Manager"
                            description="Please contact Peter for our rental rates and up-to-date availability."
                            slug="peter_newbury"
                            email="&#x73;&#x6D;&#x68;&#x61;&#x6C;&#x6C;&#x68;&#x69;&#x72;&#x65;&#x40;&#x67;&#x6D;&#x61;&#x69;&#x6C;&#x2E;&#x63;&#x6F;&#x6D;"
                            telephone="&#x30;&#x37;&#x37;&#x32;&#x30;&#x20;&#x32;&#x38;&#x35;&#x20;&#x36;&#x30;&#x36;"
                        />
                    </article>
                </div>
            </section>
        );
    }
}

export default VenuesPage;
