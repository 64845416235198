import React, { Component } from "react";
import BackgroundImage from "./PageTitle/background.jpg"

const backgroundStyle = {
    backgroundImage: "url(" + BackgroundImage + ")"
};

class PageTitle extends Component {
    render() {
        return (
            <div className="page-head" data-bg-image={BackgroundImage} style={backgroundStyle}>
                <div className="container">
                    <h1 className="page-title">
                        {this.props.text}
                    </h1>
                </div>
            </div>
        );
    }
}

export default PageTitle;
