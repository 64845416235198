import React from "react";
import { NavLink, Link } from "react-router-dom"

import "./DonationsMenuItem.css";

import DonationsImage from "./DonationsMenuItem/donations-tile.jpg";
import DonationsImage2x from "./DonationsMenuItem/donations-tile@2x.jpg";
import GiveALittleLogoImage from "./DonationsMenuItem/givealittle-logo.png";
import GiveALittleLogoImage2x from "./DonationsMenuItem/givealittle-logo@2x.png";
import AmazonSmileLogoImage from "./DonationsMenuItem/amazon_smile-logo.png";
import AmazonSmileLogoImage2x from "./DonationsMenuItem/amazon_smile-logo@2x.png";
import EasyfundraisingLogoImage from "./DonationsMenuItem/easyfundraising-logo.png";
import EasyfundraisingLogoImage2x from "./DonationsMenuItem/easyfundraising-logo@2x.png";

const DonationsMenuItem = () => {
    return (
        <li className="menu-item mega-drop-down donations">
            <NavLink to="#" activeClassName="current-menu-item" className="drop">
                Donations
                <small>How to help</small>
            </NavLink>
            <div className="animated fadeIn mega-menu">
                <div className="mega-menu-wrap">
                    <div className="row">
                        <div className="col-md-6">
                            <h4 className="row mega-title direct-donations">Direct Donations</h4>
                            <p>
                                Whether you wish to make a one-off contribution or set up regular giving, you can click on the image below to use Give A Little to make a quick and easy no-fuss donation to St Michael's:
                            </p>
                            <div className="effect-grid">
                                <figure className="effect-goliath">
                                    <a href="https://givealittle.co/campaigns/6ebcdf56-de4e-48e9-815b-a19fb8d16a3f" target="_blank" rel="noreferrer">
                                        <img src={DonationsImage} srcSet={DonationsImage+' 1x,'+DonationsImage2x+' 2x'} width="500" height="250" alt="" />
                                        <figcaption>
                                            <h2>Make your valuable contribution here</h2>
                                            <p>
                                                Click to donate with Give A Little  <span className="fa fa-chevron-right" />
                                                <img src={GiveALittleLogoImage} srcSet={GiveALittleLogoImage+' 1x,'+GiveALittleLogoImage2x+' 2x'} width="32" height="32" alt="" className="give-a-little" />
                                            </p>
                                        </figcaption>
                                    </a>
                                </figure>
                            </div>
                        </div>
                        <div className="col-md-6 passive-giving">
                            <h4 className="row mega-title">Passive Giving</h4>
                            <p>
                                Donations can be made without having to spend any additional money. There are several retail organisations who will give back some of what you spend - with no extra cost to you.
                            </p>
                            <p>
                                To find out how we can benefit through your regular shopping, click on the following links:
                            </p>
                            <ul>
                                <li>
                                    <Link to="/amazon-smile">
                                        <img src={AmazonSmileLogoImage} srcSet={AmazonSmileLogoImage+' 1x,'+AmazonSmileLogoImage2x+' 2x'}  height="34" width="100" alt="Passive donation with Amazon Smile" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/easyfundraising">
                                        <img src={EasyfundraisingLogoImage} srcSet={EasyfundraisingLogoImage+' 1x,'+EasyfundraisingLogoImage2x+' 2x'} height="34" width="100" alt="Passive donation with Easyfundraising" />
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/passive-giving" className="view-more btn-">
                                        Read more about Passive Donations &nbsp;
                                        <span className="fa fa-chevron-right" />
                                    </Link>
                                </li>
                            </ul>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
}

export default DonationsMenuItem;
