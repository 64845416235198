import { Component } from "react";
import PlaceholderImage from "./ContactMesage/placeholder.png";
import "./ContactMessage.css";
import { Link, Redirect } from "react-router-dom";
import $ from "jquery";
import "ddslick/src/jquery.ddslick.js";

class ContactMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      recipient_name: "",
      from_name: "",
      from_email_address: "",
      from_telephone_number: "",
      is_valid: false,
      subject: "",
      message: "",
    };
  }

  updateRecipientNameChange(event) {
    this.setState({ recipient_name: event.target.value });
  }

  onIsValidChange(event) {
    this.setState({ is_valid: event.target.value });
  }

  onRecipientNameChange(event) {
    this.setState({ recipient_name: event.target.value });
  }

  onFromNameChange(event) {
    this.setState({ from_name: event.target.value });
  }

  onFromEmailAddressChange(event) {
    this.setState({ from_email_address: event.target.value });
  }

  onFromTelephoneNumberChange(event) {
    this.setState({ from_telephone_number: event.target.value });
  }

  onSubjectChange(event) {
    this.setState({ subject: event.target.value });
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value });
  }

  componentDidMount() {
    $(document).ready(function () {
      $("#person-htmlselect").ddslick({
        width: "100%",
        onSelected: function (data) {
          $("#recipient_name_hidden").val(data.selectedData["value"]);
          // eslint-disable-next-line no-extra-bind
        }.bind(this),
      });
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    // eslint-disable-next-line react/no-direct-mutation-state
    this.state["recipient_name"] = this.recipient_name.value;
    console.log(this.state);

    fetch("https://fgqiv4t3e2.execute-api.eu-west-1.amazonaws.com/v1/submit-contact-form-message", {
      method: "POST",
      mode: "no-cors",
      // credentials: "same-origin",
      body: JSON.stringify(this.state),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.text())
      .finally(() =>
        this.setState({
          redirect: true,
        })
      );
  }

  render() {
    const { redirect } = this.state;

    if (redirect) {
      return <Redirect to="/contact/thank-you" />;
    }

    return (
      <section id="contact-message">
        <h3>Send us a message</h3>
        <p>Please fill out your details below to send us a message.</p>
        <form id="contact-form" onSubmit={this.handleSubmit.bind(this)} method="POST">
          <div className="form-group">
            <label htmlFor="select-person">Send message to:</label>
            <select id="person-htmlselect" className="form-control" ref={(c) => (this.recipient_name = c)}>
              <option value="" data-imagesrc={PlaceholderImage} data-description="Please select who you would like to send your message to">
                Please select...
              </option>
              <option value="stephen_taylor" data-imagesrc="/images/portraits/stephen_taylor-portrait.jpg" data-description="Vicar for St Michael's">
                Father Stephen Taylor
              </option>
              <option value="andrew_lawrence" data-imagesrc="/images/portraits/andrew_lawrence-portrait.jpg" data-description="Churchwarden">
                Andrew Lawrence
              </option>
              <option value="andrew_howarth" data-imagesrc="/images/portraits/andrew_howarth-portrait.jpg" data-description="Churchwarden">
                Andrew Howarth
              </option>
              <option value="peter_newbury" data-imagesrc="/images/portraits/peter_newbury-portrait.jpg" data-description="Halls Manager">
                Peter Newbury
              </option>
              <option value="martin_reilly" data-imagesrc="/images/portraits/martin_reilly-portrait.jpg" data-description="Youth Worker">
                Martin Reilly
              </option>
              <option value="cate_brooks" data-imagesrc="/images/portraits/cate_brooks-portrait.jpg" data-description="Community &amp; Outreach">
                Cate Brooks
              </option>
              <option value="simon_lee" data-imagesrc="/images/portraits/simon_lee-portrait.jpg" data-description="Church Safeguarding Officer">
                Simon Lee
              </option>
              <option value="paul_merryfellow" data-imagesrc={PlaceholderImage} data-description="Webmaster">
                Paul Merryfellow
              </option>
            </select>
            <input
              type="hidden"
              id="recipient_name_hidden"
              name="recipient_name"
              value={this.state.recipient_name}
              onChange={this.onRecipientNameChange.bind(this)}
              required
            />
            <input type="hidden" id="is_valid" name="is_valid" value={this.state.is_valid} onChange={this.onIsValidChange.bind(this)} required />
          </div>
          <div className="form-group form-group-from_name">
            <label htmlFor="from_name">Your name:</label>
            <input
              type="text"
              name="from_name"
              maxLength="64"
              className="form-control"
              value={this.state.from_name}
              onChange={this.onFromNameChange.bind(this)}
              required
            />
          </div>
          <div className="form-group form-group-from_email_address">
            <label htmlFor="from_email_address">Your email address:</label>
            <input
              type="email"
              name="from_email_address"
              maxLength="254"
              className="form-control"
              value={this.state.from_email_address}
              onChange={this.onFromEmailAddressChange.bind(this)}
              required
            />
            <small className="form-text text-muted">
              Please read our{" "}
              <Link to="/privacy-policy" target="_blank" rel="noreferrer">
                Privacy Policy
              </Link>{" "}
              if you would like to see how we use and store your details.
            </small>
          </div>
          <div className="form-group form-group-from_telephone_number">
            <label htmlFor="from_telephone_number">Your telephone number:</label>
            <input
              type="text"
              name="from_telephone_number"
              maxLength="20"
              className="form-control"
              value={this.state.from_telephone_number}
              onChange={this.onFromTelephoneNumberChange.bind(this)}
            />
          </div>
          <div className="form-group form-group-subject">
            <label htmlFor="subject">Subject:</label>
            <input
              type="text"
              name="subject"
              maxLength="64"
              className="form-control"
              value={this.state.subject}
              onChange={this.onSubjectChange.bind(this)}
            />
            <small className="form-text text-muted">A brief summary of the nature of your message</small>
          </div>
          <div className="form-group form-group-message">
            <label htmlFor="message">Message:</label>
            <textarea
              name="message"
              cols="40"
              rows="10"
              className="form-control"
              value={this.state.message}
              onChange={this.onMessageChange.bind(this)}
            />
            <small className="form-text text-muted" />
          </div>
          <button type="submit" className="btn- btn-sm button">
            Send Message
          </button>
        </form>
      </section>
    );
  }
}

export default ContactMessage;
