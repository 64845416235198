import {Component} from "react";

import PageTitle from "../components/page_title/PageTitle";

import AmazonSmileInstructionsThumbnailImage from "./AmazonSmilePage/amazon_smile_instructions-thumb.jpg";
import AmazonSmileInstructionsThumbnailImage2x from "./AmazonSmilePage/amazon_smile_instructions-thumb@2x.jpg";

class AmazonSmilePage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <section id="amazon-smile">
                <PageTitle text="Amazon Smile"/>

                <div className="fullwidth-block">
                    <article className="container">
                        <h2>Setting up Amazon Smile</h2>
                        <div className="row">
                            <div className="col-md-9">
                                <div className="content">
                                    <p>
                                        Amazon Smile is a fantastic way to start donating to St Michael's without
                                        spending anything extra. You simply set it up (using our downloadable PDF
                                        guide), and start shopping on Amazon as usual. As long as you replace the "www"
                                        in th URL with "smile", Amazon will make a donation of a percentage of the cost
                                        on eligible purchases to the church.
                                    </p>
                                    <p>
                                        You can use your existing Amazon account, or sign up with a new one directly on
                                        the Smile site.
                                    </p>
                                    <p>
                                        Thanks to everyone who has already signed up; every donation is appreciated, and
                                        the more people who use it, the more effective it is for us.
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3 document-download">
                                <div className="download-thumbnail">
                                    <a href="/files/amazon_smile_instructions.pdf" target="_blank" rel="noreferrer">
                                        <h5>Amazon Smile Instructions</h5>
                                        <img src={AmazonSmileInstructionsThumbnailImage}
                                             srcSet={AmazonSmileInstructionsThumbnailImage + ' 1x,' + AmazonSmileInstructionsThumbnailImage2x + ' 2x'}
                                             alt=""/>
                                        <p>
                                            <i className="fa fa-download"/> Download
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        );
    }
}

export default AmazonSmilePage;
