import {Component} from "react";

import PageTitle from "../components/page_title/PageTitle"

class HistoryPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <PageTitle text="History"/>

                <div className="fullwidth-block">
                    <article className="container">
                        <p>
                            In late 1872, St Andrew’s parish church was no longer able to cater to the needs of a
                            growing congregation that had been increasing in number due to the expansion of North
                            Enfield as a response to the popularity of the railways. As such, it was proposed to
                            consider the erection of a new "annexe" to St Andrew’s. The generosity of several
                            benefactors resulted in the laying of the memorial stone for the new church in 1873, and the
                            completion of the building in 1874.
                        </p>
                        <p>
                            Originally built as a “daughter” church of St. Andrew’s, St. Michael’s and All Angels was
                            constituted a parish church in its own right in 1931 – prior to this, all confirmations,
                            weddings and funerals had to take place at the “mother” church in Enfield Town. The Reverend
                            John Mare, who had been a very popular curate in charge of St. Michael’s for ten years by
                            that point and had served St. Michael’s for thirty years overall was inducted as the first
                            Vicar of the new parish on the 17th of January 1931. He retired in 1936 at the age of 91.
                        </p>
                        <p>
                            During the second world war, the church escaped serious damage due to the steep slope of its
                            roof – incendiary bombs simply rolled off the roof and burnt out harmlessly in the grounds!
                            However, many church records were lost due to a V2 rocket exploding in the gardens between
                            Lavender Hill and the upper part of Gordon Hill, which extensively damaged the house that
                            was being used as the vicarage. The current vicarage next to the church was built in the mid
                            1950s, once building materials were more commonly available in the post war years.
                        </p>
                        <p>
                            The present entrance to the church was added when the church building was “finished” in 1963
                            – prior to this the entrance door was in the centre of the building.
                        </p>
                        <p>
                            The church celebrated its centenary in 1974, with two special services in May of that year –
                            children from St. Michael’s school attended a flower service and older members of the
                            congregation attended a service in the evening presided over by the Vicar, the Reverend
                            Vernon Scott and the Reverend Dennis Connor, Rural Dean of Hillingdon and a former member of
                            St. Michael's congregation.
                        </p>
                        <p>
                            By the mid 1980s, the church was showing signs of wear, and the then incumbent, Reverend
                            Raymond Brown, made an appeal to parishioners in 1988 for funding to repair the stonework
                            and windows, renovate the church organ, and repair and maintain the church halls for
                            community use. The appeal was successful, and work continued for many years, culminating
                            with the renovation of the halls in 2010.
                        </p>
                    </article>
                </div>
            </div>
        );
    }
}

export default HistoryPage;
