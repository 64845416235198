import React from "react";
import { Link, NavLink } from "react-router-dom"
import NewsImage from "./CommunityMenuItem/news-tile.jpg"
// import EventsImage from "./CommunityMenuItem/events-tile.jpg"
import CommunityNoticeBoardImage from "./CommunityMenuItem/notice_board-tile.jpg"

import "./CommunityMenuItem.css"

const CommunityMenuItem = () => {
    return (
        <li className="menu-item blog-drop-down community">
            <NavLink to="#" activeClassName="current-menu-item" className="drop">
                Community
                <small>News &amp; Events</small>
            </NavLink>
            <div className="animated fadeIn blog-menu">
                <div className="mega-menu-wrap container parent">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="effect-grid">
                                <h2>News &amp; Events</h2>
                                <figure className="effect-goliath">
                                    <Link to="/news-and-events">
                                        <img src={NewsImage} width="256" height="192" alt="" />
                                        {/* srcSet={LogoImage + ' 1x,' + LogoImage2x + ' 2x'} */}
                                        <figcaption>
                                            <p>
                                                Read our latest news
                                            </p>
                                        </figcaption>
                                    </Link>
                                </figure>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="effect-grid">
                                <h2>Notice Board</h2>
                                <figure className="effect-goliath">
                                    <Link to="/notice-board">
                                        <img src={CommunityNoticeBoardImage} width="256" height="192" alt="" />
                                        {/* srcSet={LogoImage + ' 1x,' + LogoImage2x + ' 2x'} */}
                                        <figcaption>
                                            <p>
                                                Read our community notice board
                                            </p>
                                        </figcaption>
                                    </Link>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
}

export default CommunityMenuItem;
