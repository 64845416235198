import LogoImage from "./DECCard/logo.png";
import LogoImage2x from "./DECCard/logo@2x.png";

import "./DECCard.css"

const DECCard = () => (
    <div className="card dec">
        <div className="card-body">
            <div className="text-center">
                <a href="https://www.dec.org.uk" target="_blank" rel="noreferrer">
                    <img src={LogoImage}
                         srcSet={LogoImage + ' 1x,' + LogoImage2x + ' 2x'}
                         alt="" width={200} height={100} />
                </a>
            </div>
            <h5 className="card-title text-center">Ukraine Appeal</h5>
            <p className="card-text text-center">
                As many will want to help out with the crisis affecting the people of Ukraine, St Michael's encourages you to give your support through the Disasters Emergency Committee.
            </p>
            <p className="card-text text-center">
                For information on how you can help during these difficult times, please visit the DEC website by clinking on the link below:
            </p>
            <div className="text-center">
                <a href="https://www.dec.org.uk" className="btn btn-outline-secondary" target="_blank" rel="noreferrer">
                    Donate to the Appeal
                </a>
            </div>
        </div>
    </div>
);

export default DECCard;