import { Component } from "react";

import PageTitle from "../components/page_title/PageTitle";

class SchoolAdmissionsPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <section id="school-admissions">
        <PageTitle text="School Admissions" />

        <div className="fullwidth-block">
          <article className="container">
            <h2>Latest School Admissions Information</h2>
            <h4>St Michael's Parish School</h4>
            <p>
              For a Foundation place, Church attendance is <span className="font-weight-bold">24 months</span> prior to application for admission.
            </p>
            <p>
              Please note that attendance on Wednesday morning 'counts back' for the previous Sunday, and is{" "}
              <span className="font-weight-bold">NOT</span> a separate mark.
            </p>
            <h4>Wren Academy Enfield</h4>
            <p>
              For a Foundation place, Church attendance is <span className="font-weight-bold">24 months</span> prior to application for admission.
            </p>
            <p>
              Please note for Wren Enfield that only attendance on a <span className="font-weight-bold">Sunday</span> is accepted.
            </p>
            <h2>Church attendance registers</h2>
            <p>
              It is for Church Schools to determine admission criteria based on Church attendance in conjunction with the London Diocesan Board for
              Schools. Parents should familiarise themselves with church attendance criteria applicable to September 2023 and beyond for both primary
              and secondary school places, including any updates as a consequence of the COVID-19 pandemic.
            </p>
            {/* <p className="font-weight-bold">
                    Time slots for Supplementary form signing for September 2022 Church Primary School Admissions will be given at the 10am Epiphany Sunday Service on 2 January 2022
            </p> */}
          </article>
        </div>
      </section>
    );
  }
}

export default SchoolAdmissionsPage;
