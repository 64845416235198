import React from "react";
import "./FacilitiesList.css"

const FacilitiesList = () => {
    return (
        <section id="facilities-list">
            <h3>Facilities</h3>
            <ul className="col-md-6">
                <li>
                    <i className="fa fa-wheelchair" />
                    Wheelchair Access
                </li>
                <li>
                    <i className="fa fa-deaf" />
                    Hearing Loop
                </li>
                <li>
                    <i className="fa fa-bus-alt" />
                    Good Public Transport Links
                </li>
                <li>
                    <i className="fa fa-car" />
                    Parking Available
                </li>
                <li>
                    <i className="fa fa-child" />
                    Children Clubs &amp; Groups
                </li>
            </ul>
            <ul className="col-md-6">
                <li>
                    <i className="fas fa-key" />
                    Hall Hire
                </li>
                
                {/*<li>*/}
                {/*    <i className="fas fa-theater-masks" />*/}
                {/*    Stage Hire*/}
                {/*</li>*/}
                
                <li>
                    <i className="fa fa-utensils" />
                    Kitchen Facilities
                </li>
                <li>
                    <i className="fa fa-coffee" />
                    Tea &amp; Coffee Facilities
                </li>
                <li>
                    <i className="fa fa-restroom" />
                    Toilets
                </li>
            </ul>
        </section>
    );
}

export default FacilitiesList;