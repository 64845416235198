import React from "react";

import "./MainMenu.css"
import {Link} from "react-router-dom";

const MainMobileMenu = () => {
    return (
        <ul className="collapse mobile-menu menu" id="mobileNav">
            <li>
                <Link to="/">
                    Welcome
                </Link>
            </li>
            <li>
                <Link to="/worship">
                    Service Times
                </Link>
            </li>
            <li>
                <Link to="/news-and-events">
                    News &amp; Events
                </Link>
            </li>
            <li>
                <Link to="/childrens-clubs">
                    Sunday Clubs &amp; Children's Groups
                </Link>
            </li>
            <li>
                <Link to="/choir">
                    Choir
                </Link>
            </li>
            <li>
                <Link to="/serving-team">
                    Serving Team
                </Link>
            </li>
            <li>
                <Link to="/community-and-outreach">
                    Community &amp; Outreach
                </Link>
            </li>
            <li>
                <Link to="/autumn-group">
                    Autumn Group
                </Link>
            </li>
            <li>
                <Link to="/venues">
                    Venues &amp; Hall Hire
                </Link>
            </li>
            <li>
                <a href="https://givealittle.co/campaigns/6ebcdf56-de4e-48e9-815b-a19fb8d16a3f" target="_blank" rel="noreferrer">
                    Make a Donation (External Site)
                </a>
            </li>
            <li>
                <Link to="/easyfundraising">
                    Easyfundraising
                </Link>
            </li>
            <li>
                <Link to="/amazon-smile">
                    Amazon Smile
                </Link>
            </li>
            <li>
                <Link to="/passive-giving">
                    About Passive Giving
                </Link>
            </li>
            <li>
                <Link to="/whos-who">
                    Who's Who?
                </Link>
            </li>
            <li>
                <Link to="/school-admissions">
                    School Admissions
                </Link>
            </li>
            <li>
                <Link to="/history">
                    History
                </Link>
            </li>
            <li>
                <Link to="/children">
                    Children
                </Link>
            </li>
            <li>
                <Link to="/contact">
                    Contact
                </Link>
            </li>
        </ul>
    );
}

export default MainMobileMenu;
