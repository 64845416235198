import React from "react";
import { NavLink } from "react-router-dom"

const ContactMenuItem = () => {
    return (
        <li className="menu-item contact-us">
            <NavLink to="/contact" activeClassName="current-menu-item">
                Contact
                <small>Get in touch</small>
            </NavLink>
        </li>
    );
}

export default ContactMenuItem;
