import React from "react";

const ParkingInformation = () => {
    return (
        <section>
            <h3>Parking</h3>
            <p>
                Parking can usually be found in and around the Church, however it is well served by Public Transport too, which as a nationally recognised eco-Church we would always encourage people to use.
            </p>
        </section>
    );
}

export default ParkingInformation;