import "./RedBookCartCard.css"

const RedBookCartCard = () => (
    <div className="card redbookcart">
        <div className="card-body">
            <h5 className="card-title text-center">The <span>Red</span> Book Cart</h5>
            <p className="card-text">
                Take a look at our fantastic selection of books on the red cart at the back of the church.
            </p>
            <p className="card-text">
                You can pick up and enjoy a good read from our wonderful selection of page-turners - and with all proceeds go to help the running costs of St Michael's, everyone benefits!
            </p>
        </div>
    </div>
);

export default RedBookCartCard;