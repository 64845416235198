import React from "react";
import { NavLink } from "react-router-dom"

const WorshipMenuItem = () => {
    return (
        <li className="menu-item">
            <NavLink to="/worship" activeClassName="current-menu-item">
                Worship Times
                <small>Services &amp; Prayer</small>
            </NavLink>
        </li>
    );
}

export default WorshipMenuItem;
