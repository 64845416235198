import React from "react";
import { NavLink } from "react-router-dom"

const HomeMenuItem = () => {
    return (
        <li className="menu-item">
            <NavLink to="/" activeClassName="current-menu-item" exact>
                Home
                <small>Welcome</small>
            </NavLink>
        </li>
    );
}

export default HomeMenuItem;
