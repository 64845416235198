import React from "react";
import { Link } from "react-router-dom"
import DioceseOfLondonLogo from "./PageFooter/diocese-of-london.png";
import DioceseOfLondonLogo2x from "./PageFooter/diocese-of-london@2x.png";
import EcoChurchAwardLogo from "./PageFooter/ecochurch-2022-bronze-award.png";
import EcoChurchAwardLogo2x from "./PageFooter/ecochurch-2022-bronze-award@2x.png";

const PageFooter = () => {
    return (
        <footer className="site-footer">
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="widget">
                            <h2 className="church-mantra">
                                Confidence
                                <img src="/images/ui/bullet.png" alt="" width="7" height="8" />
                                Compassion
                                <img src="/images/ui/bullet.png" alt="" width="7" height="8" />
                                Creativity
                            </h2>
                            <p>
                                <b>Reaching up:</b> building on our roots so that together we can reach up to God with thanks and praise. <b>Reaching out:</b> extending our branches and reaching out to share God's love with all. <b>Reaching in:</b> reaching in to ourselves and nurturing our faith in God.
                            </p>
                            <p>
                                <b>Worship, Friendship, Fun:</b> St Michael's is a community that is welcoming and accepting of all. We are a large, vibrant, very family-friendly congregation, with a fresh approach to worship - <i>"Informal Formality"</i>.
                            </p>
                            <p>
                                We're here for you and we can't wait to meet you - come and join us, we're certain you won't be disappointed!
                            </p>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="widget">
                            <h2>Important Information</h2>
                            <ul>
                                <li>
                                    <Link to="/safeguarding">
                                        Safeguarding Information
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/privacy-policy">
                                        Privacy Policy (GDPR)
                                    </Link>
                                </li>
                                {/*<li>*/}
                                {/*    <Link to="/cookies">*/}
                                {/*        Website Cookies*/}
                                {/*    </Link>*/}
                                {/*</li>*/}
                            </ul>
                            <div className="text-center mt-3 mb-3">
                                <a href="https://ecochurch.arocha.org.uk/" target="_blank" rel="noreferrer">
                                    <img src={EcoChurchAwardLogo} srcSet={EcoChurchAwardLogo+' 1x,'+EcoChurchAwardLogo2x+' 2x'} alt="Eco-Church 2023 Silver Award" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <div className="widget">
                            <h3>Social Media</h3>
                            <div className="social-media-icon-links">
                                <a href="https://www.facebook.com/StMichaelAllAngelsChurchEnfield" className="facebook" target="_blank" rel="noreferrer">
                                    <i className="fab fa-facebook-f" />
                                </a>
                                <a href="https://twitter.com/stmichaelsgh" className="twitter" target="_blank" rel="noreferrer">
                                    <i className="fab fa-twitter" />
                                </a>
                                <a href="https://www.youtube.com/channel/UC4ZOW1EA8KSjOMFuJsv9HBA" className="youtube" target="_blank" rel="noreferrer">
                                    <i className="fab fa-youtube" />
                                </a>
                            </div>
                            <br />
                            <p className="text-center">
                                <a href="https://www.london.anglican.org/" target="_blank" rel="noreferrer">
                                    <img src={DioceseOfLondonLogo} srcSet={DioceseOfLondonLogo+' 1x,'+DioceseOfLondonLogo2x+' 2x'} alt="Diocese of London" />
                                </a>
                            </p>
                        </div>
                    </div>
                </div>
                <p className="colophon text-center">
                    &copy; {(new Date().getFullYear())} St Michael and All Angels Church, Enfield. All right reserved.
                </p>
            </div>
        </footer>
    );
}

export default PageFooter;
