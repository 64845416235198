import {Component} from "react";

import PageTitle from "../components/page_title/PageTitle"
import ShortBio from "../components/bio/ShortBio";

import AltarImage from "./ServingTeamPage/altar.jpg"
import AltarImage2x from "./ServingTeamPage/altar@2x.jpg"

import "./ServingTeamPage.css"

class ServingTeamPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <section id="serving-team">
                <PageTitle text="The Serving Team"/>

                <div className="fullwidth-block">
                    <article className="container">
                        <div className="row">
                            <div>
                                <img src={AltarImage} srcSet={AltarImage + ' 1x,' + AltarImage2x + ' 2x'} width="300"
                                     height="200" alt="" className="main-image-right"/>
                                <p>
                                    Our Sunday services are greatly enhanced by our fantastic Serving Team, who ensure
                                    the ceremonial aspect of our services go off without a hitch. There are several jobs
                                    which can be done to help, and anyone of school age 3 or up can join in the fun!
                                </p>
                                <p>
                                    If you would like to see what you can do to add to our services, please contact Pam
                                    Thompson for further details.
                                </p>
                            </div>
                        </div>
                        <ShortBio
                            name="Pamela Thompson"
                            shortName="Pam"
                            role="Head of Serving Team & Secretary of the PCC"
                            description="For any enquiries about joining the serving team, please contact Pam for more information."
                            slug="pamela_thompson"
                        />
                    </article>
                </div>
            </section>
        );
    }
}

export default ServingTeamPage;
