import { Component } from "react";

import PageTitle from "../components/page_title/PageTitle";
import ShortBio from "../components/bio/ShortBio";
import VicarBio from "../components/bio/VicarBio";

class WhosWhoPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <section id="whos-who_page">
        <PageTitle text="Who's Who" />

        <div className="fullwidth-block">
          <article className="container">
            <p>Here we have a list of names and faces of people you are likely to meet when visiting St Michael's.</p>
            <div className="row">
              <div className="col-md-12">
                <VicarBio
                  name="Fr Stephen Taylor"
                  shortName="Fr Stephen"
                  role="Vicar"
                  description=""
                  address="St Michael's Vicarage, 2 Gordon Hill, Enfield, Middlesex EN2 0QP"
                  telephone="020 8363 1063"
                  email="stephen.taylor@london.anglican.org"
                  slug="stephen_taylor"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <ShortBio name="Andrew Lawrence" shortName="Andrew" role="Churchwarden" description="" slug="andrew_lawrence" />
              </div>
              <div className="col-md-6">
                <ShortBio name="Andrew Howarth" shortName="Andrew" role="Churchwarden" description="" slug="andrew_howarth" />
              </div>
              <div className="col-md-6">
                <ShortBio
                  name="Pamela Thompson"
                  shortName="Pam"
                  role="Head of Serving Team &amp; Secretary of the PCC"
                  description=""
                  slug="pamela_thompson"
                />
              </div>
              <div className="col-md-6">
                <ShortBio name="Peter Newbury" shortName="Peter" role="Assistant Warden &amp; Halls Manager" description="" slug="peter_newbury" />
              </div>
              <div className="col-md-6">
                <ShortBio
                  name="Martin Reilly"
                  shortName="Martin"
                  role="Youth Worker"
                  description="As well as being our Youth Worker, Martin also runs the Cornerstones Youth Club on Thursday evenings."
                  slug="martin_reilly"
                />
              </div>
              <div className="col-md-6">
                <ShortBio
                  name="Simon Lee"
                  shortName="Simon"
                  role="Church Safeguarding Officer"
                  description="Our Safeguarding Officer is the first person to speak to if you have any concerns around the welfare of a child or vulnerable adult."
                  slug="simon_lee"
                />
              </div>
              <div className="col-md-6">
                <ShortBio
                  name="Laura Willis"
                  shortName="Laura"
                  role="Children's Champion"
                  description="The Children's Champion is here to be an advocate for all children in all areas of Parish life, making sure their views are heard and their needs are thought of."
                  slug="laura_willis"
                />
              </div>
              <div className="col-md-6">
                <ShortBio
                  name="Mrs J Bacon"
                  shortName="Mrs J Bacon"
                  role="Head Mistress (St Michael's CE Primary, the Parish School)"
                  description=""
                  website="http://www.st-michaels.enfield.sch.uk/"
                  slug="j_bacon"
                />
              </div>
            </div>
          </article>
        </div>
      </section>
    );
  }
}

export default WhosWhoPage;
