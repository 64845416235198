import {Component} from "react";

import PageTitle from "../components/page_title/PageTitle";

import EasyfundraisingLogoImage from "./EasyfundraisingPage/logo.png";
import EasyfundraisingLogoImage2x from "./EasyfundraisingPage/logo@2x.png";

import "./EasyfundraisingPage.css"

class EasyfundraisingPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <section id="easyfundraising">
                <PageTitle text="Easyfundraising"/>

                <h2 className="text-center">
                    <img src={EasyfundraisingLogoImage}
                         srcSet={EasyfundraisingLogoImage + ' 1x,' + EasyfundraisingLogoImage2x + ' 2x'}
                         alt="Easyfundraising: Feel good shopping"/>
                </h2>
                <div className="fullwidth-block">
                    <article className="container">
                        <p>
                            Did you know that whenever you buy anything online – from your weekly shop to your annual
                            holiday – you could be raising free donations for St Michael and All Angels Church with
                            easyfundraising?
                        </p>
                        <p>
                            There are over 4,000 shops and sites on board ready to make a donation – including eBay,
                            Argos, John Lewis, ASOS, Booking.com and M&S – and it won’t cost you a penny extra to help
                            us raise funds.
                        </p>
                        <p>
                            All you need to do is:
                        </p>
                        <ol>
                            <li>
                                Go to <a href="https://www.easyfundraising.org.uk/causes/smaaenfield/" target="_blank"
                                         rel="noreferrer">https://www.easyfundraising.org.uk/causes/smaaenfield/</a> and
                                join for free.
                            </li>
                            <li>
                                Every time you shop online, go to easyfundraising first to find the site you want and
                                start shopping.
                            </li>
                            <li>
                                After you’ve checked out, the retailer will make a donation to St Michael's at no extra
                                cost to you whatsoever!
                            </li>
                        </ol>
                        <p>
                            There are no catches or hidden charges and St Michael's will be really grateful for your
                            donations.
                        </p>
                        <p>
                            Thank you for your support.
                        </p>
                    </article>
                </div>
            </section>
        );
    }
}

export default EasyfundraisingPage;
