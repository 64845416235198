import React from "react";
import { NavLink } from "react-router-dom"
import BackgroundImage from "./ChildrenMenuItem/children-background.png"

const ChildrenMenuItem = () => {
    return (
        <li className="menu-item blog-drop-down children" style={{backgroundImage: `url(${BackgroundImage})`, backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundPosition: "center"}}>
            <NavLink to="/children" activeClassName="current-menu-item" className="drop">
                Children
                <small>Fun for Kids</small>
            </NavLink>
        </li>
    );
}

export default ChildrenMenuItem;
