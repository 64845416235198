import {Component} from "react";

class GirlguidingPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <h2>Brownies &amp; Guides</h2>
                <p>
                    These groups meet in the Celebration Hall on Tuesday evenings.
                </p>
                <dl>
                    <dt>Brownies</dt>
                    <dd>6 p.m.</dd>
                    <dt>Guides</dt>
                    <dd>7.30 p.m.</dd>
                </dl>
                <p>
                    The girls undertake numerous and varied activities and are always having fun!
                </p>
                <p>
                    For more information please email <a
                    href="mailto:&#x61;&#x63;&#x6F;&#x72;&#x72;&#x79;&#x36;&#x37;&#x39;&#x40;&#x62;&#x74;&#x69;&#x6E;&#x74;&#x65;&#x72;&#x6E;&#x65;&#x74;&#x2E;&#x63;&#x6F;&#x6D;">&#x61;&#x63;&#x6F;&#x72;&#x72;&#x79;&#x36;&#x37;&#x39;&#x40;&#x62;&#x74;&#x69;&#x6E;&#x74;&#x65;&#x72;&#x6E;&#x65;&#x74;&#x2E;&#x63;&#x6F;&#x6D;</a> for
                    Brownies, and <a
                    href="mailto:&#x32;&#x34;&#x74;&#x68;&#x65;&#x6E;&#x66;&#x69;&#x65;&#x6C;&#x64;&#x67;&#x75;&#x69;&#x64;&#x65;&#x73;&#x40;&#x67;&#x6D;&#x61;&#x69;&#x6C;&#x2E;&#x63;&#x6F;&#x6D;">&#x32;&#x34;&#x74;&#x68;&#x65;&#x6E;&#x66;&#x69;&#x65;&#x6C;&#x64;&#x67;&#x75;&#x69;&#x64;&#x65;&#x73;&#x40;&#x67;&#x6D;&#x61;&#x69;&#x6C;&#x2E;&#x63;&#x6F;&#x6D;</a> for
                    Guides.
                </p>
            </div>
        );
    }
}

export default GirlguidingPage;
