import React, { Component } from "react";
// import {Link} from "react-router-dom";

class EventDetailsItem extends Component {
  render() {
    return (
      <div className="list-item event">
        <div>
          <img src={`/images/service-types/${this.props.serviceType}.png`} className="item-thumbnail" alt="" width="32" height="32" />
          <h3 style={{ display: "inline-block" }} dangerouslySetInnerHTML={{ __html: this.props.name }}></h3>
          <h3 style={{ display: "inline-block" }}>
            <small>{this.props.date}</small>
          </h3>
        </div>
        <div dangerouslySetInnerHTML={{ __html: this.props.content }}></div>
        <div className="tags">
          {this.props.tags.split(",").map((step) => (
            <span className="tag">{step}</span>
          ))}
        </div>
      </div>
    );
  }
}

export default EventDetailsItem;
