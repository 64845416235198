import React from "react";
import BackgroundImage from "./Hero/background.jpg"

const backgroundStyle = {
    backgroundImage: "url(" + BackgroundImage + ")"
};

const Hero = () => {
    return (
        <div className="hero">
            <ol className="slides">
                <li data-bg-image={BackgroundImage} data-rjs="2" style={backgroundStyle}>
                    <div className="container">
                        <div className="slide-content row">
                            <div className="col-xl-6 intro-quote">
                                We're here for you and we can't wait to meet you - come and join us, we're certain you won't be disappointed!
                            </div>
                            <div className="col-xl-6 embedded-media-wrapper">
                                {/*<span className="decoration" />*/}
                                <iframe title="Welcome page intro"
                                        src="https://www.youtube.com/embed/l1TqWPYabi8?iv_load_policy=0&amp;modestbranding=1&amp;rel=0"
                                        className="embedded-media fancy"
                                        width="540"
                                        height="340"
                                        allow="accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture"
                                        allowFullScreen />
                            </div>
                        </div>
                    </div>
                </li>
            </ol>
        </div>
    );
}

export default Hero;
