import React, { Component } from "react";

import EventListItem from "./EventListItem";

const eventData = require("../events.json");
let eventListItems;

class EventList extends Component {
  getEventListItem(item) {
    console.log(item);
    if (eventListItems.length < 7 && item.featured) {
      eventListItems.push(<EventListItem name={item.name} serviceType={item.serviceType} date={item.date} />);
    }
  }

  constructor(props) {
    super(props);

    eventListItems = [];
    eventData.events.forEach(this.getEventListItem);
  }

  render() {
    return <ul className="homepage-list">{eventListItems}</ul>;
  }
}

export default EventList;
