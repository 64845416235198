import {Component} from "react";

import PageTitle from "../components/page_title/PageTitle";
import "./PrivacyPolicyPage.css"

class PrivacyPolicyPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <section id="privacy-policy">
                <PageTitle text="Privacy Policy"/>

                <div className="fullwidth-block">
                    <article className="container">
                        <h2>General Privacy Notice</h2>
                        <h3>Your personal data – what is it?</h3>
                        <p>
                            "Personal data" is any information about a living individual which allows them to be
                            identified from that data (for example a name, photographs, videos, email address, or
                            address). Identification can be by the information alone or in conjunction with any other
                            information. The processing of personal data is governed by <i>[the Data Protection Bill/Act
                            2017 the General Data Protection Regulation 2016/679 (the "<abbr
                                title="General Data Protection Regulation">GDPR</abbr>" and other legislation relating
                            to personal data and rights such as the Human Rights Act 1998]</i>.
                        </p>
                        <h3>Who are we?</h3>
                        <p>
                            This Privacy Notice is provided to you by the Parochial Church Council (PCC) of St
                            Michael &amp; All Angels, Enfield which is the data controller for your data.
                        </p>
                        <p>
                            The Church of England is made up of a number of different organisations and office-holders
                            who work together to deliver the Church's mission in each community. The <abbr
                            title="Parochial Church Council">PCC</abbr> works together with:
                        </p>
                        <ul>
                            <li>
                                the incumbent of the parish (that is, our Vicar);
                            </li>
                            <li>
                                the bishops of the Diocese of London; and
                            </li>
                            <li>
                                the London Diocesan Fund, which is responsible for the financial and administrative
                                arrangements for the Diocese of London.
                            </li>
                        </ul>
                        <p>
                            As the Church is made up of all of these persons and organisations working together, we may
                            need to share personal data we hold with them so that they can carry out their
                            responsibilities to the Church and our community. The organisations referred to above are
                            joint data controllers. This means we are all responsible to you for how we process your
                            data.
                        </p>
                        <p>
                            Each of the data controllers have their own tasks within the Church and a description of
                            what data is processed and for what purpose is set out in this Privacy Notice. This Privacy
                            Notice is sent to you by the <abbr title="Parochial Church Council">PCC</abbr> on our own
                            behalf and on behalf of each of these data controllers. In the rest of this Privacy Notice,
                            we use the word "we" to refer to each data controller, as appropriate.
                        </p>
                        <h3>What data do the data controllers listed above process?</h3>
                        <p>
                            They will process some or all of the following where necessary to perform their tasks:
                        </p>
                        <ul>
                            <li>
                                Names, titles, and aliases, photographs;
                            </li>
                            <li>
                                Contact details such as telephone numbers, addresses, and email addresses;
                            </li>
                            <li>
                                Where they are relevant to our mission, or where you provide them to us, we may process
                                demographic information such as gender, age, date of birth, marital status, nationality,
                                education/work histories, academic/professional qualifications, hobbies, family
                                composition, and dependants;
                            </li>
                            <li>
                                Where you make donations or pay for activities such as use of a church hall, financial
                                identifiers such as bank account numbers, payment card numbers, payment/transaction
                                identifiers, policy numbers, and claim numbers;
                            </li>
                            <li>
                                The data we process is likely to constitute sensitive personal data because, as a
                                church, the fact that we process your data at all may be suggestive of your religious
                                beliefs. Where you provide this information, we may also process other categories of
                                sensitive personal data: racial or ethnic origin, sex life, mental and physical health,
                                details of injuries, medication/treatment received, political beliefs, labour union
                                affiliation, genetic data, biometric data, data concerning sexual orientation and
                                criminal records, fines and other similar judicial records.
                            </li>
                        </ul>
                        <h3>How do we process your personal data?</h3>
                        <p>
                            The data controllers will comply with their legal obligations to keep personal data up to
                            date; to store and destroy it securely; to not collect or retain excessive amounts of data;
                            to keep personal data secure, and to protect personal data from loss, misuse, unauthorised
                            access and disclosure and to ensure that appropriate technical measures are in place to
                            protect personal data. We use your personal data for some or all of the following purposes:
                        </p>
                        <ul>
                            <li>
                                To enable us to meet all legal and statutory obligations (which include maintaining and
                                publishing our electoral roll in accordance with the Church Representation Rules);
                            </li>
                            <li>
                                To carry out comprehensive safeguarding procedures (including due diligence and
                                complaints handling) in accordance with best safeguarding practice from time to time
                                with the aim of ensuring that all children and adults-at-risk are provided with safe
                                environments;
                            </li>
                            <li>
                                To minister to you and provide you with pastoral and spiritual care (such as visiting
                                you when you are gravely ill or bereaved) and to organise and perform ecclesiastical
                                services for you, such as baptisms, confirmations, weddings and funerals;
                            </li>
                            <li>
                                To deliver the Church's mission to our community, and to carry out any other voluntary
                                or charitable activities for the benefit of the public as provided for in the
                                constitution and statutory framework of each data controller;
                            </li>
                            <li>
                                To administer the parish, deanery, archdeaconry and diocesan membership records;
                            </li>
                            <li>
                                To fundraise and promote the interests of the Church and charity;
                            </li>
                            <li>
                                To maintain our own accounts and records;
                            </li>
                            <li>
                                To record details of church attendance, to support applications for entry to St
                                Michael's or other church schools
                            </li>
                            <li>
                                To process a donation that you have made (including Gift Aid information);
                            </li>
                            <li>
                                To seek your views or comments;
                            </li>
                            <li>
                                To notify you of changes to our services, events and role holders;
                            </li>
                            <li>
                                To send you communications which you have requested and that may be of interest to you.
                                These may include information about campaigns, appeals, other fundraising activities;
                            </li>
                            <li>
                                To process a grant or application for a role;
                            </li>
                            <li>
                                To enable us to provide a voluntary service for the benefit of the public in a
                                particular geographical area as specified in our constitution;
                            </li>
                            <li>
                                Our processing also includes the use of <abbr
                                title="Closed-circuit television">CCTV</abbr> systems for the prevention and prosecution
                                of crime.
                            </li>
                        </ul>
                        <h3>What is the legal basis for processing your personal data?</h3>
                        <p>
                            Most of our data is processed because it is necessary for our legitimate interests, or the
                            legitimate interests of a third party (such as another organisation in the Church of
                            England). An example of this would be our safeguarding work to protect children and adults
                            at risk. We will always take into account your interests, rights and freedoms.
                        </p>
                        <p>
                            Some of our processing is necessary for compliance with a legal obligation. For example, we
                            are required by the Church Representation Rules to administer and publish the electoral
                            roll, and under Canon Law to announce forthcoming weddings by means of the publication of
                            banns.
                        </p>
                        <p>
                            We may also process data if it is necessary for the performance of a contract with you, or
                            to take steps to enter into a contract. An example of this would be processing your data in
                            connection with the hire of church facilities.
                        </p>
                        <p>
                            Religious organisations are also permitted to process information about your religious
                            beliefs to administer membership or contact details. Where your information is used other
                            than in accordance with one of these legal bases, we will first obtain your consent to that
                            use.
                        </p>
                        <h3>Sharing your personal data</h3>
                        <p>
                            Your personal data will be treated as strictly confidential. It will only be shared with
                            third parties where it is necessary for the performance of our tasks or where you first give
                            us your prior consent. It is likely that we will need to share your data with some or all of
                            the following (but only where necessary):
                        </p>
                        <ul>
                            <li>
                                The appropriate bodies of the Church of England including the other data controllers;
                            </li>
                            <li>
                                Our agents, servants and contractors. For example, we may ask a commercial provider to
                                send out newsletters on our behalf, or to maintain our database software;
                            </li>
                            <li>
                                Other clergy or lay persons nominated or licensed by the bishops of the Diocese of
                                London to support the mission of the Church in our parish. For example, our clergy are
                                supported by our area dean and archdeacon, who may provide confidential mentoring and
                                pastoral support. Assistant or temporary ministers, including curates, deacons, licensed
                                lay ministers, commissioned lay ministers or persons with Bishop's Permissions may
                                participate in our mission in support of our regular clergy;
                            </li>
                            <li>
                                Other persons or organisations operating within the Diocese of London including, where
                                relevant, the London Diocesan Board for Schools and Subsidiary Bodies;
                            </li>
                            <li>
                                On occasion, other churches with which we are carrying out joint events or activities.
                            </li>
                        </ul>
                        <h3>How long do we keep your personal data?</h3>
                        <p>
                            We will keep some records permanently if we are legally required to do so. We may keep some
                            other records for an extended period of time. For example, it is current best practice to
                            keep financial records for a minimum period of 7 years to support <abbr
                            title="HM Revenue and Customs">HMRC</abbr> audits. In general, we will endeavour to keep
                            data only for as long as we need it. This means that we may delete it when it is no longer
                            needed.
                        </p>
                        <h3>Your rights and your personal data</h3>
                        <p>
                            You have the following rights with respect to your personal data:
                        </p>
                        <p>
                            When exercising any of the rights listed below, in order to process your request, we may
                            need to verify your identity for your security. In such cases we will need you to respond
                            with proof of your identity before you can exercise these rights.
                        </p>
                        <ol>
                            <li>
                                The right to access information we hold on you
                                <ul>
                                    <li>
                                        At any point you can contact us to request the information we hold on you as
                                        well as why we have that information, who has access to the information and
                                        where we obtained the information from. Once we have received your request we
                                        will respond within one month.
                                    </li>
                                    <li>
                                        There are no fees or charges for the first request but additional requests for
                                        the same data may be subject to an administrative fee.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                The right to correct and update the information we hold on you
                                <ul>
                                    <li>
                                        If the data we hold on you is out of date, incomplete or incorrect, you can
                                        inform us, and your data will be updated.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                The right to have your information erased
                                <ul>
                                    <li>
                                        If you feel that we should no longer be using your data or that we are illegally
                                        using your data, you can request that we erase the data we hold.
                                    </li>
                                    <li>
                                        When we receive your request, we will confirm whether the data has been deleted
                                        or the reason why it cannot be deleted (for example because we need it for our
                                        legitimate interests or regulatory purpose(s)).
                                    </li>
                                </ul>
                            </li>
                            <li>
                                The right to object to processing of your data
                                <ul>
                                    <li>
                                        You have the right to request that we stop processing your data. Upon receiving
                                        the request, we will contact you and let you know if we are able to comply or if
                                        we have legitimate grounds to continue to process your data. Even after you
                                        exercise your right to object, we may continue to hold your data to comply with
                                        your other rights or to bring or defend legal claims.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                The right to data portability
                                <ul>
                                    <li>
                                        You have the right to request that we transfer some of your data to another
                                        controller. We will comply with your request, where it is feasible to do so,
                                        within one month of receiving your request.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                The right to withdraw your consent to the processing at any time for any processing of
                                data to which consent was sought.
                                <ul>
                                    <li>
                                        You can withdraw your consent easily by telephone, email, or by post (see
                                        Contact Details below).
                                    </li>
                                </ul>
                            </li>
                            <li>
                                The right to object to the processing of personal data where applicable.
                            </li>
                            <li>
                                The right to lodge a complaint with the Information Commissioner's Office.
                            </li>
                        </ol>
                        <h3>Transfer of Data Abroad</h3>
                        <p>
                            Any electronic personal data transferred to countries or territories outside the <abbr
                            title="European Union">EU</abbr> will only be placed on systems complying with measures
                            giving equivalent protection of personal rights either through international agreements or
                            contracts approved by the European Union. Our website is also accessible from overseas so on
                            occasion some personal data (for example in a newsletter) may be accessed from overseas.
                        </p>
                        <h3>Further processing</h3>
                        <p>
                            If we wish to use your personal data for a new purpose, not covered by this Notice, then we
                            will provide you with a new notice explaining this new use prior to commencing the
                            processing and setting out the relevant purposes and processing conditions. Where and
                            whenever necessary, we will seek your prior consent to the new processing.
                        </p>
                        <h3>Contact Details</h3>
                        <p>
                            Please contact us if you have any questions about this Privacy Notice or the information we
                            hold about you or to exercise all relevant rights, queries or complaints at:
                        </p>
                        <address>
                            The Data Controller,<br/>
                            St Michael &amp; All Angels,<br/>
                            Gordon Hill,<br/>
                            Enfield,<br/>
                            Middlesex,<br/>
                            EN2 0QP<br/>
                            Email: <a
                            href="mailto:&#115;&#116;&#101;&#112;&#104;&#101;&#110;&#46;&#116;&#97;&#121;&#108;&#111;&#114;&#64;&#108;&#111;&#110;&#100;&#111;&#110;&#46;&#97;&#110;&#103;&#108;&#105;&#99;&#97;&#110;&#46;&#111;&#114;&#103;">&#115;&#116;&#101;&#112;&#104;&#101;&#110;&#46;&#116;&#97;&#121;&#108;&#111;&#114;&#64;&#108;&#111;&#110;&#100;&#111;&#110;&#46;&#97;&#110;&#103;&#108;&#105;&#99;&#97;&#110;&#46;&#111;&#114;&#103;</a>
                        </address>
                        <p>
                            You can contact the Information Commissioners Office on <a
                            href="tel:&#48;&#51;&#48;&#51;&#32;&#49;&#50;&#51;&#32;&#49;&#49;&#49;&#51;">&#48;&#51;&#48;&#51;&#32;&#49;&#50;&#51;&#32;&#49;&#49;&#49;&#51;</a> or
                            via email <a href="https://ico.org.uk/global/contact-us/email/" target="_blank"
                                         rel="noreferrer">https://ico.org.uk/global/contact-us/email/</a> or at the
                            Information Commissioner's Office, Wycliffe House, Water Lane, Wilmslow, Cheshire SK9 5AF.
                        </p>
                    </article>
                </div>
            </section>
        );
    }
}

export default PrivacyPolicyPage;
