import {Component} from "react";

import PageTitle from "../components/page_title/PageTitle";

import "./SafeguardingPage.css";

import ShortBio from "../components/bio/ShortBio";
import SafeguardingPosterThumbnailImage from "./SafeguardingPage/parish-safeguarding-info-poster-2023-07.png";
import SafeguardingPosterThumbnailImage2x from "./SafeguardingPage/parish-safeguarding-info-poster-2023-07@2x.png";
import DioceseOfLondonLogoImage from "./SafeguardingPage/diocese-logo.png";
import DioceseOfLondonLogoImage2x from "./SafeguardingPage/diocese-logo@2x.png";


class SafeguardingPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <section id="safeguarding">
                <PageTitle text="Safeguarding"/>

                <div className="fullwidth-block">
                    <article className="container">
                        <h2>Ensuring the enjoyment of worship and community in a safe environment</h2>
                        <div className="row">
                            <div className="col-md-9">
                                <div className="content">
                                    <p>
                                        The safety and well-being of all people associated with the church, particularly
                                        those who are vulnerable in any way, is of paramount importance to us. We have
                                        committed to a Safeguarding policy which has been agreed with the Diocese of
                                        London, outlined below for your inspection.
                                    </p>
                                    <p>
                                        We have also produced a distilled version of the statement in the form of a
                                        poster, which is available for download by clicking <a
                                        href="/files/parish-safeguarding-info-poster-2023-07.pdf" target="_blank"
                                        rel="noreferrer">here</a> or on the thumbnail image to the right.
                                    </p>
                                    <p>
                                        If you would like to talk to somebody about a safeguarding issue, please contact
                                        Simon Lee (Church Safeguarding Officer) or Laura Willis (Children's Champion)
                                        - more details at the bottom of this page.
                                    </p>
                                    <p>
                                        The full Safeguarding Policy Statement is as follows:
                                    </p>
                                </div>
                            </div>
                            <div className="col-md-3 document-download">
                                <div className="download-thumbnail">
                                    <a href="/files/parish-safeguarding-info-poster-2023-07.pdf" target="_blank"
                                       rel="noreferrer">
                                        <h5>Safeguarding Poster</h5>
                                        <img src={SafeguardingPosterThumbnailImage}
                                             srcSet={SafeguardingPosterThumbnailImage + ' 1x,' + SafeguardingPosterThumbnailImage2x + ' 2x'}
                                             alt=""/>
                                        <p>
                                            <i className="fa fa-download"/> Download
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="centre-header">
                            <p>
                                <img src={DioceseOfLondonLogoImage}
                                     srcSet={DioceseOfLondonLogoImage + ' 1x,' + DioceseOfLondonLogoImage2x + ' 2x'}
                                     width="218" height="100" alt="Diocese of London"/>
                            </p>
                            <h3>Parish of Enfield, St Michael</h3>
                            <h4>Safeguarding Policy: Promoting a Safer Church</h4>
                        </div>
                        <p>
                            The following policy was agreed at the Parochial Church Council (PCC) meeting held on 14 May
                            2024.
                        </p>
                        <p>
                            In accordance with the Church of England Safeguarding Policy our Church is committed to:
                        </p>
                        <ul>
                            <li>
                                Promoting a safer environment and culture.
                            </li>
                            <li>
                                Safely recruiting and supporting all those with any responsibility related to children,
                                young people and vulnerable adults within the church.
                            </li>
                            <li>
                                Responding promptly to every safeguarding concern or allegation.
                            </li>
                            <li>
                                Caring pastorally for victims/survivors of abuse and other affected persons.
                            </li>
                            <li>
                                Caring pastorally for those who are the subject of concerns or allegations of abuse and
                                other affected persons.
                            </li>
                            <li>
                                Responding to those that may pose a present risk to others.
                            </li>
                        </ul>
                        <p>
                            The Parish will:
                        </p>
                        <ul>
                            <li>
                                Create a safe and caring place for all.
                            </li>
                            <li>
                                Have a named Parish Safeguarding Officer (PSO) to work with the incumbent and the PCC to
                                implement policy and procedures.
                            </li>
                            <li>
                                Safely recruit, train and support all those with any responsibility for children, young
                                people and adults to have the confidence and skills to recognise and respond to abuse.
                            </li>
                            <li>
                                Ensure that there is appropriate insurance cover for all activities involving children
                                and adults undertaken in the name of the parish.
                            </li>
                            <li>
                                Display in church premises and on the Parish website the details of who to contact if
                                there are safeguarding concerns or support needs.
                            </li>
                            <li>
                                Listen to and take seriously all those who disclose abuse.
                            </li>
                            <li>
                                Take steps to protect children and adults when a safeguarding concern of any kind
                                arises, following House of Bishops guidance, including notifying the Diocesan
                                Safeguarding Adviser (DSA) and statutory agencies immediately.
                            </li>
                            <li>
                                Offer support to victims/survivors of abuse regardless of the type of abuse, when or
                                where it occurred.
                            </li>
                            <li>
                                Care for and monitor any member of the Church community who may pose a risk to children
                                and adults whilst maintaining appropriate confidentiality and the safety of all parties.
                            </li>
                            <li>
                                Ensure that health and safety policy, procedures and risk assessments are in place and
                                that these are reviewed annually.
                            </li>
                            <li>
                                Review the implementation of the Safeguarding Policy, Procedures and Practices at least
                                annually.
                            </li>
                        </ul>
                        <p>
                            Each person who works within this Church community will agree to abide by this policy and
                            the guidelines established by this Church.
                        </p>
                        <p>
                            This Church appoints Mr Simon Lee as the Parish Safeguarding Officer.
                        </p>
                        <p>
                            This statement has been signed by Fr S Taylor (Vicar), Mr A Lawrence (Churchwarden) and Mr A
                            Howarth (Churchwarden) dated 14 May 2024.
                        </p>
                        <div className="row">
                            <div className="col-md-6">
                                <ShortBio
                                    name="Simon Lee"
                                    shortName="Simon"
                                    role="Church Safeguarding Officer"
                                    description="Our Safeguarding Officer is the first person to speak to if you have any concerns around the welfare of a child or vulnerable adult."
                                    slug="simon_lee"
                                />
                            </div>
                            <div className="col-md-6">
                                <ShortBio
                                    name="Laura Willis"
                                    shortName="Laura"
                                    role="Children's Champion"
                                    description="The Children's Champion is here to be an advocate for all children in all areas of Parish life, making sure their views are heard and their needs are thought of."
                                    slug="laura_willis"
                                />
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        );
    }
}

export default SafeguardingPage;
