import {Component} from "react";

import { Link } from "react-router-dom"

import PageTitle from "../components/page_title/PageTitle";
import AmazonSmileLogoImage from "./PassiveGivingPage/amazon_smile-logo.png";
import EasyfundraisingLogoImage from "./PassiveGivingPage/easy_fundraising-logo.png";

import "./PassiveGivingPage.css"

class PassiveGivingPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <section id="passive-giving">
                <PageTitle text="Passive Giving"/>

                <div className="fullwidth-block">
                    <article className="container">
                        <h2>Passive Giving (<abbr title="Frequently Asked Questions">F.A.Q.</abbr>)</h2>
                        <h3>What is passive giving?</h3>
                        <p>
                            In short, passive giving is a fantastic way to donate to St Michael's without having to
                            spend any money beyond your usual shopping spends.
                        </p>
                        <p>
                            The way it works is certain organisations give you the ability to register your desire to
                            support the church and you continue to shop with them pretty much as usual. When you make a
                            purchase, a percentage of their profit is redirected to St Michael's in the form of a
                            charitable donation.
                        </p>
                        <p>
                            These organisations still receive their profit, and charities like St Michael's get to
                            benefit from the arrangement without costing you anything you wouldn't already be spending.
                        </p>
                        <p>
                            In summary, you get to help the church financially (at no extra cost to yourself), the
                            church receives additional funding, and the places you shop still make a profit - it's a
                            real win-win-win situation!
                        </p>
                        <h3>How much does this change the price of what I buy?</h3>
                        <p>
                            Not al all: you will still pay whatever price you would normally pay for an item; the
                            passive donation is taken from that organisation's profits.
                        </p>
                        <h3>How much of my purchase price is donated?</h3>
                        <p>
                            The percentage varies from vendor-to-vendor, and item-to-item. Whilst the percentage is not
                            particularly large per item, if as many people as possible used this feature the cumulative
                            effect makes a valuable contribution to the running of St Michael's.
                        </p>
                        <h3>Is it difficult to register/use?</h3>
                        <p>
                            The two organisations we use at the moment are Amazon ("Amazon Smile" to be specific) and
                            Easyfundraising. The signup process is different for each, and full instruction can be found
                            at the following pages:
                        </p>
                        <ul>
                            <li>
                                <Link to="/amazon-smile">
                                    <img src={AmazonSmileLogoImage} height="36"
                                         alt="Passive donation with Amazon Smile"/>
                                </Link>
                            </li>
                            <li>
                                <Link to="/easyfundraising">
                                    <img src={EasyfundraisingLogoImage} height="36"
                                         alt="Passive donation with Easyfundraising"/>
                                </Link>
                            </li>
                        </ul>
                        <p>
                            Once you have signed up for the service(s), you can continue to shop the way you normally
                            would and the donations will be made in the background without any further action on your
                            part.
                        </p>
                        <br/>
                        <p className="text-center font-weight-bold">
                            We would like to thank you for considering using these valuable means of donation,
                            <br/>
                            and a special thanks to everyone who is contributing using passive giving already.
                        </p>
                    </article>
                </div>
            </section>
        );
    }
}

export default PassiveGivingPage;
