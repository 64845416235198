import "./GirlguidingCard.css"

// noinspection HtmlUnknownTarget
const GirlguidingCard = () => (
    <div className="card girlguiding">
        <div className="bd-placeholder-img card-img-top" title="Girlguiding: We discover, we grow" />

        <div className="card-body">
            <h5 className="card-title">Brownies &amp; Guides</h5>
            <p className="card-text">
                The Brownie and Guide troops meet in the Celebration Hall on Tuesday evenings where the girls undertake numerous and varied activities and are always having fun!
            </p>
            <ul>
                <li>
                    Brownies: 6 p.m.
                </li>
                <li>
                    Guides: 7.30 p.m.
                </li>
            </ul>
            <p>
                For more information, please contact the relevant Troop Leader below:
            </p>
            <div className="text-center">
                <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#97;&#99;&#111;&#114;&#114;&#121;&#54;&#55;&#57;&#64;&#98;&#116;&#105;&#110;&#116;&#101;&#114;&#110;&#101;&#116;&#46;&#99;&#111;&#109;" className="btn btn-outline-secondary">Click for Brownies</a>
                &nbsp;
                <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#99;&#108;&#97;&#114;&#101;&#115;&#97;&#100;&#108;&#101;&#114;&#95;&#56;&#55;&#64;&#103;&#109;&#97;&#105;&#108;&#46;&#99;&#111;&#109;" className="btn btn-outline-secondary">Click for Guides</a>
            </div>
        </div>
    </div>
);

export default GirlguidingCard;