import { Component } from "react";

import PageTitle from "../components/page_title/PageTitle";

import ChildrensActivityDownload from "../components/children/ChildrensActivityDownload";

import FaithAtHomeLogoImage from "./ChildrenPage/faith_at_home.png";
import FaithAtHomeLogoImage2x from "./ChildrenPage/faith_at_home@2x.png";

import ScriptureUnionLogoImage from "./ChildrenPage/scripture_union.png";
import ScriptureUnionLogoImage2x from "./ChildrenPage/scripture_union@2x.png";

import "./ChildrenPage.css";

class ChildrenPage extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <section id="children">
        <PageTitle text="Children's Activities" />

        <div className="fullwidth-block">
          <article className="container">
            <h2>Weekly Activity Sheets</h2>
            <p>Click on the thumbnails below to download fun children's activities in PDF format (which you can print out).</p>
            <div className="row childrens-downloads">
              <ChildrensActivityDownload
                name="YCM Quiz Sheet"
                slug="quiz_sheet"
                thumbnail_width="175"
                thumbnail_height="250"
                publisher="ycm"
                size="3"
              />

              <ChildrensActivityDownload
                name="YCM Monthly Colouring Sheet"
                slug="monthly_colouring_sheet"
                thumbnail_width="175"
                thumbnail_height="250"
                publisher="ycm"
                size="3"
              />

              {/* <ChildrensActivityDownload
                name="Extra Colouring Sheet"
                slug="extra_colouring_sheet"
                thumbnail_width="175"
                thumbnail_height="250"
                publisher="ycm"
                size="4"
              /> */}

              <ChildrensActivityDownload
                name="Roots Activity Sheet"
                slug="activity_sheet"
                thumbnail_width="175"
                thumbnail_height="250"
                publisher="roots"
                size="3"
              />

              <ChildrensActivityDownload
                name="Roots Colouring Sheet"
                slug="colouring_sheet"
                thumbnail_width="175"
                thumbnail_height="250"
                publisher="roots"
                size="3"
              />
            </div>
            <h3>Extra Downloads</h3>
            <div className="row more-childrens-downloads">
              <ChildrensActivityDownload
                name="Books of the Old Testament Colouring Sheet"
                slug="books_of_the_old_testament"
                thumbnail_width="140"
                thumbnail_height="182"
                publisher="ministry-to-children.com"
                size="4"
              />

              <ChildrensActivityDownload
                name="Gifts of the Holy Spirit Activity Sheet"
                slug="gifts_of_the_holy_spirit_activity_sheet"
                thumbnail_width="140"
                thumbnail_height="200"
                publisher="twinkl"
                size="4"
              />

              <ChildrensActivityDownload
                name="Gifts of the Holy Spirit Wordsearch"
                slug="gifts_of_the_holy_spirit_wordsearch"
                thumbnail_width="140"
                thumbnail_height="200"
                publisher="twinkl"
                size="4"
              />

              <ChildrensActivityDownload
                name="Jonah and the Big Fish"
                slug="jonah_and_the_big_fish"
                thumbnail_width="140"
                thumbnail_height="106"
                publisher="twinkl"
                size="4"
              />

              <ChildrensActivityDownload
                name="Loaves and Fishes"
                slug="loaves_and_fishes"
                thumbnail_width="140"
                thumbnail_height="106"
                publisher="twinkl"
                size="4"
              />

              <ChildrensActivityDownload
                name="Moses and the Story of Passover"
                slug="moses_and_the_story_of_passover"
                thumbnail_width="140"
                thumbnail_height="106"
                publisher="twinkl"
                size="4"
              />
            </div>
            <h3>Further Resources</h3>
            <ul id="further-resources">
              <li>
                <a href="https://www.churchofengland.org/our-faith/faith-home" target="_blank" rel="noreferrer">
                  <img
                    src={FaithAtHomeLogoImage}
                    srcSet={FaithAtHomeLogoImage + " 1x," + FaithAtHomeLogoImage2x + " 2x"}
                    alt="Church of England's Faith at Home website"
                  />
                </a>
              </li>
              <li>
                <a href="https://content.scriptureunion.org.uk/" target="_blank" rel="noreferrer">
                  <img
                    src={ScriptureUnionLogoImage}
                    srcSet={ScriptureUnionLogoImage + " 1x," + ScriptureUnionLogoImage2x + " 2x"}
                    alt="Scripture Union"
                  />
                </a>
              </li>
            </ul>
            <br />
          </article>
          <div className="container">
            © ROOTS for Churches Ltd,{" "}
            <a href="https://www.rootsontheweb.com/" target="_blank" rel="noreferrer">
              www.rootsontheweb.com
            </a>
            . Reproduced with permission.
          </div>
        </div>
      </section>
    );
  }
}

export default ChildrenPage;
