import React from "react";
import { Link } from "react-router-dom"
import LogoImage from "./PageHeader/logo.png";
import LogoImage2x from "./PageHeader/logo@2x.png";

const PageHeader = () => {
    return (
        <Link to="/" className="branding">
            <img src={LogoImage} srcSet={LogoImage+' 1x,'+LogoImage2x+' 2x'} alt="" className="logo" />
            <h1 className="site-title">
                St Michael and All Angels Church
                <small>Enfield</small>
            </h1>
        </Link>
    );
}

export default PageHeader;
