import React, { Component } from "react";

import "./ChildrensActivityDownload.css"


class ChildrensActivityDownload extends Component {
    render() {
        return (
            <div className={"col-md-" + this.props.size + " childrens-download"}>
                <a href={"/files/children/" + this.props.publisher + "/" + this.props.slug + ".pdf"} target="_blank" rel="noreferrer">
                    <h3>
                        {this.props.name}
                    </h3>
                    <img src={"/files/children/" + this.props.publisher + "/" + this.props.slug + "-thumb.jpg"} alt="" width={this.props.thumbnail_width} height={this.props.thumbnail_height} />
                </a>
            </div>
        );
    }
}

export default ChildrensActivityDownload;
