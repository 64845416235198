import React from "react";
import { NavLink, Link } from "react-router-dom";

import "./GroupsAndClubsMenuItem.css";

import ChoirThumbnailImage from "./GroupsAndClubsMenuItem/choir-thumbnail.jpg";
import ServingTeamThumbnailImage from "./GroupsAndClubsMenuItem/serving-team-thumbnail.jpg";
import CommunityAndOutreachThumbnailImage from "./GroupsAndClubsMenuItem/community_and_outreach-thumbnail.jpg"
import AutumnGroupThumbnailImage from "./GroupsAndClubsMenuItem/autumn_group-thumbnail.jpg";
import ChildrensClubsImage from "./GroupsAndClubsMenuItem/clubs-tile.jpg";
import ChildrensClubsImage2x from "./GroupsAndClubsMenuItem/clubs-tile@2x.jpg";

const GroupsAndClubsMenuItem = () => {
    return (
        <li className="menu-item mega-drop-down" id="groups-and-clubs">
            <NavLink to="#" activeClassName="current-menu-item" className="drop">
                Social
                <small>Groups &amp; Clubs</small>
            </NavLink>
            <div className="animated fadeIn mega-menu">
                <div className="mega-menu-wrap">
                    <div className="row">
                        <div className="col-md-6">
                            <h4 className="row mega-title">Children's Clubs</h4>
                            <ul className="stander groups-children">
                                <li className="row">
                                    <div className="col-md-12">
                                        <p className="mb-1">
                                            Our large and thriving Sunday School is made up of four exciting and educational groups: Pebbles, Rocks, Boulders, and Cornerstones.
                                        </p>
                                        <div className="row">
                                            {/*<div className="col-md-4">*/}
                                            {/*    <Link to="/sunday-clubs" className="view-more btn btn-sm">*/}
                                            {/*        <img src="/images/menu/toddler-group.png" alt="" />*/}
                                            {/*        <br />*/}
                                            {/*        Sunday Clubs <span className="fa fa-chevron-right" />*/}
                                            {/*    </Link>*/}
                                            {/*</div>*/}
                                            {/*<div className="col-md-4">*/}
                                            {/*    <Link to="/cornerstones" className="view-more btn btn-sm">*/}
                                            {/*        <img src="/images/menu/toddler-group.png" alt=" " />*/}
                                            {/*        <br />*/}
                                            {/*        Cornerstones <span className="fa fa-chevron-right" />*/}
                                            {/*    </Link>*/}
                                            {/*</div>*/}
                                            {/*<div className="col-md-4">*/}
                                            {/*    <Link to="/toddler-group" className="view-more btn btn-sm">*/}
                                            {/*        <img src="/images/menu/toddler-group.png" alt=" " />*/}
                                            {/*        <br />*/}
                                            {/*        Toddler Group <span className="fa fa-chevron-right" />*/}
                                            {/*    </Link>*/}
                                            {/*</div>*/}
                                            {/*<div className="col-md-4">*/}
                                            {/*    <Link to="/girlguiding" className="view-more btn- btn-sm">*/}
                                            {/*        <img src="/images/menu/guides.png" alt=" " />*/}
                                            {/*        <br />*/}
                                            {/*        GirlguidingCard <span className="fa fa-chevron-right" />*/}
                                            {/*    </Link>*/}
                                            {/*</div>*/}

                                            <div className="effect-grid">
                                                <figure className="effect-goliath">
                                                    <Link to="/childrens-clubs">
                                                        <img src={ChildrensClubsImage} srcSet={ChildrensClubsImage+' 1x,'+ChildrensClubsImage2x+' 2x'} width="500" height="250" alt="" />
                                                        <figcaption>
                                                            <h2>&nbsp;</h2>
                                                            <p>
                                                                Our Sunday Clubs and Children's Groups  <span className="fa fa-chevron-right" />
                                                            </p>
                                                        </figcaption>
                                                    </Link>
                                                </figure>
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div className="col-md-6">
                            <h4 className="row mega-title">Other Groups</h4>
                            <ul className="stander groups-adults">
                                <li className="row">
                                    <div className="col-md-2">
                                        <img src={ChoirThumbnailImage} width="76" height="64" alt="" />
                                    </div>
                                    <div className="col-md-7">
                                        <div className="group-header">
                                            <h6>Choir</h6>
                                        </div>
                                        <p>
                                            Help us enrich our Sunday service by making a joyful noise
                                        </p>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="/choir" className="view-more btn- btn-sm button">
                                            Read More
                                            &nbsp;
                                            <span className="fa fa-chevron-right" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="row">
                                    <div className="col-md-2">
                                        <img src={ServingTeamThumbnailImage} width="76" height="64" alt="" />
                                    </div>
                                    <div className="col-md-7">
                                        <div className="group-header">
                                            <h6>The Serving Team</h6>
                                            <span className="age-group">School year 3 and over</span>
                                        </div>
                                        <p>
                                            Why not join us in adding some character and style to our worship?
                                        </p>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="/serving-team" className="view-more btn- btn-sm button">
                                            Read More &nbsp;<span className="fa fa-chevron-right" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="row">
                                    <div className="col-md-2">
                                        <img src={CommunityAndOutreachThumbnailImage} width="76" height="64" alt="" />
                                    </div>
                                    <div className="col-md-7">
                                        <div className="group-header">
                                            <h6>Community &amp; Outreach</h6>
                                        </div>
                                        <p>
                                            Help keep our church community thriving with social events and fundraising
                                        </p>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="/community-and-outreach" className="view-more btn- btn-sm button">
                                            Read More &nbsp;<span className="fa fa-chevron-right" />
                                        </Link>
                                    </div>
                                </li>
                                <li className="row">
                                    <div className="col-md-2">
                                        <img src={AutumnGroupThumbnailImage} width="76" height="64" alt="" />
                                    </div>
                                    <div className="col-md-7">
                                        <div className="group-header">
                                            <h6>Autumn Group</h6>
                                            <span className="age-group">Over 60's</span>
                                        </div>
                                        <p>
                                            Enjoy anything from lunches to afternoon tea, and outings to theatre trips
                                        </p>
                                    </div>
                                    <div className="col-md-3">
                                        <Link to="/autumn-group" className="view-more btn- btn-sm button">
                                            Read More &nbsp;<span className="fa fa-chevron-right" />
                                        </Link>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
}

export default GroupsAndClubsMenuItem;
