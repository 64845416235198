import {Component} from "react";

class CornerstonesPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <h2>Cornerstones</h2>
                <p>
                    We have been running for over 9 years, inviting children from years 3-5 on the first and third
                    Thursday of the month and inviting years 6 and above on the second and fourth Thursday of the month
                    during term time.
                </p>
                <p>
                    We offer a relaxed, fun and social environment for the children to enjoy. We actives including games
                    consoles, football, basketball, pool and table tennis. We also run special themed nights including
                    arts and crafts, discos and supper nights - where we put on food and drink for the children to enjoy
                    together.
                </p>
                <p>
                    Our numbers continue to grow every year and we welcome <strong>all</strong> children - not just St
                    Michael's.
                </p>
                <p>
                    It is great value at only £1.50 entry fee!
                </p>
                <p>
                    We cannot operate without our always amazing volunteers and always looking to welcome any help
                    available.
                </p>
                <p>
                    For more information, please contact Martin Reilly by email at <a
                    href="mailto:&#x6D;&#x61;&#x72;&#x74;&#x69;&#x6E;&#x2E;&#x63;&#x6F;&#x72;&#x6E;&#x65;&#x72;&#x73;&#x74;&#x6F;&#x6E;&#x65;&#x73;&#x40;&#x67;&#x6D;&#x61;&#x69;&#x6C;&#x2E;&#x63;&#x6F;&#x6D;">&#x6D;&#x61;&#x72;&#x74;&#x69;&#x6E;&#x2E;&#x63;&#x6F;&#x72;&#x6E;&#x65;&#x72;&#x73;&#x74;&#x6F;&#x6E;&#x65;&#x73;&#x40;&#x67;&#x6D;&#x61;&#x69;&#x6C;&#x2E;&#x63;&#x6F;&#x6D;</a> or
                    by phone on <a
                    href="tel:&#x30;&#x37;&#x38;&#x37;&#x33;&#x39;&#x37;&#x39;&#x38;&#x37;&#x38;">&#x30;&#x37;&#x38;&#x37;&#x33;&#x20;&#x39;&#x37;&#x39;&#x20;&#x38;&#x37;&#x38;</a>
                </p>
            </div>
        );
    }
}

export default CornerstonesPage;
