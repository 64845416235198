import {Component} from "react";

import PageTitle from "../components/page_title/PageTitle"

import TransportLinks from "./ContactPage/TransportLinks";
import FacilitiesList from "./ContactPage/FacilitiesList";
import FindingUs from "./ContactPage/FindingUs";
import ParkingInformation from "./ContactPage/ParkingInformation";

import ContactMessage from "./ContactPage/ContactMessage";

class ContactPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <PageTitle text="Contact Us" />
                <div className="fullwidth-block">
                    <main className="container">
                        <h1>Contact</h1>
                        <div className="row">
                            <div className="content col-md-12 contact-us-page">
                                <p>
                                    If you would like to know how to find us, or if you have a query or want to send us a message, you can find our address and contact details, as well as handy information on how to get to us.
                                </p>
                                <p>
                                    There is also a contact form below if you would like to send us a message.
                                </p>
                                <div className="row">
                                    <div className="content col-md-6">
                                        <ContactMessage />
                                    </div>
                                    <div className="content col-md-6">
                                        <FindingUs/>
                                        <div className="row">
                                            <FacilitiesList/>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="content col-md-6">
                                        <TransportLinks/>
                                    </div>
                                    <div className="content col-md-6">
                                        <ParkingInformation />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        );
    }
}

export default ContactPage;
