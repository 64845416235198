import {Component} from "react";

import PageTitle from "../components/page_title/PageTitle"

class AutumnGroupPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <section id="autumn-group">
                <PageTitle text="Autumn Group"/>

                <div className="fullwidth-block">
                    <article className="container">
                        <div className="row">
                            <div>
                                <p>
                                    This group is for those who are aged 60+ and meets quarterly. Activities vary from
                                    lunches to afternoon tea, outings, theatre trips or talks and always finishes the
                                    year with a Christmas lunch in a local pub.
                                </p>
                                <p>
                                    The Autumn Group will be starting up again shortly, please keep an eye here for
                                    updates.
                                </p>
                            </div>
                        </div>
                    </article>
                </div>
            </section>
        );
    }
}

export default AutumnGroupPage;
