import LogoImage from "./CornerstonesCard/logo.png";
import LogoImage2x from "./CornerstonesCard/logo@2x.png";

import FoosballImage from "./CornerstonesCard/foosball.png";
import FoosballImage2x from "./CornerstonesCard/foosball@2x.png";
import TableTennisImage from "./CornerstonesCard/table-tennis.png";
import TableTennisImage2x from "./CornerstonesCard/table-tennis@2x.png";
import PoolImage from "./CornerstonesCard/pool.png";
import PoolImage2x from "./CornerstonesCard/pool@2x.png";
import VideoGamesImage from "./CornerstonesCard/video-games.png";
import VideoGamesImage2x from "./CornerstonesCard/video-games@2x.png";

import "./CornerstonesCard.css"

const CornerstonesCard = () => (
    <div className="card cornerstones">
        <div className="card-body">
            <div className="text-center">
                <img src={LogoImage} srcSet={LogoImage + ' 1x,' + LogoImage2x + ' 2x'} alt="" width={300} height={60} />
            </div>
            <p className="card-text text-center">
                The Cornerstones Junior Youth Group is open to School ages 3-10. We meet on Thursday evenings from 6 p.m. until 8 p.m. in the parish halls (during term-time).
            </p>
            <p className="card-text text-center">
                <img src={FoosballImage} srcSet={FoosballImage + ' 1x,' + FoosballImage2x + ' 2x'} alt="" width={60} height={75} />&nbsp;
                <img src={TableTennisImage} srcSet={TableTennisImage + ' 1x,' + TableTennisImage2x + ' 2x'} alt="" width={60} height={75} />&nbsp;
                <img src={PoolImage} srcSet={PoolImage + ' 1x,' + PoolImage2x + ' 2x'} alt="" width={60} height={75} />&nbsp;
                <img src={VideoGamesImage} srcSet={VideoGamesImage + ' 1x,' + VideoGamesImage2x + ' 2x'} alt="" width={60} height={75} />
            </p>
            <p className="card-text text-center">
                There are a range of activities, including an array of electronic gaming, table tennis, pool, table football, and more!
            </p>
            <p className="card-text text-center">
                For information, contact Martin Reilly by email or by phone:
            </p>
            <div className="text-center">
                <a href="mailto:&#x6D;&#x61;&#x72;&#x74;&#x69;&#x6E;&#x2E;&#x63;&#x6F;&#x72;&#x6E;&#x65;&#x72;&#x73;&#x74;&#x6F;&#x6E;&#x65;&#x73;&#x40;&#x67;&#x6D;&#x61;&#x69;&#x6C;&#x2E;&#x63;&#x6F;&#x6D;&#x3F;&#x73;&#x75;&#x62;&#x6A;&#x65;&#x63;&#x74;&#x3D;&#x43;&#x6F;&#x72;&#x6E;&#x65;&#x72;&#x73;&#x74;&#x6F;&#x6E;&#x65;&#x73;&#x20;&#x59;&#x6F;&#x75;&#x74;&#x68;&#x20;&#x47;&#x72;&#x6F;&#x75;&#x70;" className="btn btn-outline-secondary">
                    <i className="fa fa-envelope" />&nbsp;
                    &#x6D;&#x61;&#x72;&#x74;&#x69;&#x6E;&#x2E;&#x63;&#x6F;&#x72;&#x6E;&#x65;&#x72;&#x73;&#x74;&#x6F;&#x6E;&#x65;&#x73;&#x40;&#x67;&#x6D;&#x61;&#x69;&#x6C;&#x2E;&#x63;&#x6F;&#x6D;
                </a>
            </div>
            <div className="text-center mt-1">
                <a href="tel:&#x30;&#x37;&#x38;&#x37;&#x33;&#x39;&#x37;&#x39;&#x38;&#x37;&#x38;" className="btn btn-outline-secondary">
                    <i className="fa fa-phone" />&nbsp;
                    &#x30;&#x37;&#x38;&#x37;&#x33;&#x20;&#x39;&#x37;&#x39;&#x38;&#x37;&#x38;
                </a>
            </div>
        </div>
    </div>
);

export default CornerstonesCard;