import React, { Component } from "react";
import {Link} from "react-router-dom";

import PlaceholderImage from "./ShortBio/placeholder.png"


class ShortBio extends Component {
    render() {
        return (
            <div className="row whos-who">
                <div className="col-md-11 other-person person">
                    <span className="portrait">
                        <img src={this.props.slug ? "/images/portraits/" + this.props.slug + "-portrait.jpg" : PlaceholderImage} alt="" width="128" height="128" />
                    </span>
                    <div className="person-details">
                        <h3>{this.props.name}</h3>
                        <h4>
                            {this.props.role}
                        </h4>
                        <div className="bio if short row endif">
                            <div className="personal-bio">
                                {this.props.description}
                            </div>
                        </div>

                        {
                            this.props.website ? (
                                <div className="website if short row endif">
                                    <a href={this.props.website} className="contact-info" target="_blank" rel="noreferrer">
                                        <i className="fa fa-globe-europe" title="Website"/>
                                        Website
                                    </a>
                                </div>
                            ) : (
                                <div className="contact if short row endif">
                                    <span className="contact-info">
                                        <i className="fa fa-envelope" title="Contact"/>
                                        <Link to="/contact" params={{ send_to: this.props.shortName }}>
                                            Contact {this.props.shortName}
                                        </Link>
                                    </span>
                                </div>
                            )
                        }

                        {
                            this.props.email ? (
                                <div className="contact if short row endif">
                                    <span className="contact-info">
                                        <a href={'mailto:' + this.props.email}>
                                            <i className="fa fa-envelope" title="Email"/>
                                            Email: {this.props.email}
                                        </a>
                                    </span>
                                </div>
                            ) : ''
                        }

                        {
                            this.props.telephone ? (
                                <div className="contact if short row endif">
                                    <span className="contact-info">
                                        <a href={'tel:' + this.props.telephone}>
                                            <i className="fa fa-phone" title="Telephone Number"/>
                                            Telephone: {this.props.telephone}
                                        </a>
                                    </span>
                                </div>
                            ) : ''
                        }

                    </div>
                </div>
            </div>
        );
    }
}

export default ShortBio;
