import {Component} from "react";

import PageTitle from "../components/page_title/PageTitle"

import {Link} from "react-router-dom";
import ShortBio from "../components/bio/ShortBio";

class ChoirPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <section id="choir">
                <PageTitle text="The Choir"/>

                <div className="fullwidth-block">
                    <article className="container">
                        <p>
                            St Michael's is blessed with a fantastic musical accompaniment in the form of our choir and
                            organist.
                        </p>
                        <p>
                            If you would like to lend your dulcet tones to our wonderful choir, please <Link
                            to="/contact">contact Fr. Stephen</Link>.
                        </p>
                        <ShortBio
                            name="Fr Stephen Taylor"
                            shortName="Fr Stephen"
                            role="Vicar"
                            description="For any enquiries about joining the choir, please contact Fr Stephen for more information."
                            slug="stephen_taylor"
                        />
                    </article>
                </div>
            </section>
        );
    }
}

export default ChoirPage;
