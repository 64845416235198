import React from "react";
import { NavLink, Link } from "react-router-dom"

import WhosWhoTileImage from "./AboutMenuItem/whos_who-tile.jpg"
import SchoolAdmissionsTileImage from "./AboutMenuItem/school_admissions-tile.jpg"
// import FurnishingsAndVestmentsTileImage from "./AboutMenuItem/furnishings_and_vestments-tile.jpg"
import HistoryTileImage from "./AboutMenuItem/history-tile.jpg"

import "./AboutMenuItem.css"

const AboutMenuItem = () => {
    return (
        <li className="menu-item blog-drop-down about-us">
            <NavLink to="#" activeClassName="current-menu-item" className="drop">
                The Church
                <small>About Us</small>
            </NavLink>
            <div className="animated fadeIn blog-menu">
                <div className="mega-menu-wrap container parent">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="effect-grid">
                                <h2>Who's Who?</h2>
                                <figure className="effect-goliath">
                                    <Link to="/whos-who">
                                        <img src={WhosWhoTileImage} width="256" height="192" alt="" />
                                        {/* srcSet={LogoImage + ' 1x,' + LogoImage2x + ' 2x'} */}
                                        <figcaption>
                                            <p>
                                                Some friendly faces you will likely meet at St Michael's
                                            </p>
                                        </figcaption>
                                    </Link>
                                </figure>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="effect-grid">
                                <h2>Schools Admissions</h2>
                                <figure className="effect-goliath">
                                    <Link to="/school-admissions">
                                        <img src={SchoolAdmissionsTileImage} width="256" height="192" alt="" />
                                        {/* srcSet={LogoImage + ' 1x,' + LogoImage2x + ' 2x'} */}
                                        <figcaption>
                                            <p>
                                                The latest guidance on school admissions criteria
                                            </p>
                                        </figcaption>
                                    </Link>
                                </figure>
                            </div>
                        </div>
                        {/*<div className="col-md-3">*/}
                        {/*    <div className="effect-grid">*/}
                        {/*        <figure className="effect-goliath">*/}
                        {/*            <Link to="/furnishings-and-vestments">*/}
                        {/*                <img src={FurnishingsAndVestmentsTileImage} width="256" height="192" alt="" />*/}
                        {/*                <figcaption>*/}
                        {/*                    <p>*/}
                        {/*                        Some of the interesting things you can find around the church*/}
                        {/*                    </p>*/}
                        {/*                </figcaption>*/}
                        {/*            </Link>*/}
                        {/*        </figure>*/}
                        {/*    </div>*/}
                        {/*    <h2>Furnishings &amp; Vestments</h2>*/}
                        {/*</div>*/}
                        <div className="col-md-4">
                            <div className="effect-grid">
                                <h2>History</h2>
                                <figure className="effect-goliath">
                                    <Link to="/history">
                                        <img src={HistoryTileImage} width="256" height="192" alt="" />
                                        {/* srcSet={LogoImage + ' 1x,' + LogoImage2x + ' 2x'} */}
                                        <figcaption>
                                            <p>
                                                A short history of the church and some of the people
                                            </p>
                                        </figcaption>
                                    </Link>
                                </figure>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </li>
    );
}

export default AboutMenuItem;
