import {Component} from "react";

import PageTitle from "../components/page_title/PageTitle";

class CookiesPage extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div>
                <PageTitle text="Cookies"/>
                <h1>Cookies</h1>
            </div>
        );
    }
}

export default CookiesPage;
