import React from "react";

const FindingUs = () => {
    return (
        <section>
            <h3>Finding Us</h3>
            <p>
                Our full postal address (which you can use with a SatNav or GPS device to navigate to the Church by car or on foot) is:
            </p>
            <p className="postal-address">
                St Michael's Church,<br />
                Gordon Hill<br />
                Enfield,<br />
                Middlesex<br />
                EN2 0QP
            </p>
            <iframe
                title="Church Location"
                src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=The%20Vicarage,%202%20Gordon%20Hill%20Enfield+(St%20Michael%20and%20All%20Angels)&amp;t=&amp;z=15&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
                width="520"
                height="400"
                className="mb-5"
                id="gmap_canvas" />
            <a href="https://www.add-map.net/">How do I add a Google map to my website?</a>
            <script src="https://embedmaps.com/google-maps-authorization/script.js?id=109b563ba715d6d3a078bc4b5a3d8e7d9831ebbd" type="text/javascript" />
        </section>
    );
}

export default FindingUs;