import React, { Component } from "react";
import {Link} from "react-router-dom";

class VicarBio extends Component {
    render() {
        return (
            <div className="row whos-who">
                <div className="col-md-11 is-vicar person">
                    <span className="portrait">
                        <img src="/images/portraits/stephen_taylor-portrait.jpg" alt="" width="192" height="192" />
                    </span>
                    <div className="person-details">
                        <h3>{this.props.name}</h3>
                        <h4>
                            {this.props.role}
                        </h4>
                        <div className="bio if short row endif">
                            <div className="personal-bio">
                                {this.props.description}
                            </div>
                        </div>

                        <div className="contact">
                            { (this.props.address && this.props.address !== "") ?
                                <span className="contact-info row">
                                    <i className="fa fa-map-marked-alt" title="Postal Address" />
                                    <span className="address">
                                        {this.props.address}
                                    </span>
                                </span> : ( <span /> )
                            }

                            <span className="contact-info row">
                                <i className="fa fa-envelope" title="Email Address" />
                                <a href={"mailto:"+this.props.email}>
                                    {this.props.email}
                                </a>
                            </span>

                            <span className="contact-info row">
                                <i className="fa fa-phone" title="Telephone Number" />
                                {this.props.telephone}
                            </span>

                            { (this.props.address && this.props.address !== "") ?
                                <span className="contact-info row">
                                    <i className="fa fa-envelope" title="Contact"/>
                                    <Link to="/contact">
                                        Contact {this.props.shortName}
                                    </Link>
                                </span> : ( <span /> )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default VicarBio;
