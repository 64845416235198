import React from "react";
import CommunityMenuItem from "./menu_items/CommunityMenuItem";
import WorshipMenuItem from "./menu_items/WorshipMenuItem";
import GroupsAndClubsMenuItem from "./menu_items/GroupsAndClubsMenuItem";
import VenuesMenuItem from "./menu_items/VenuesMenuItem";
import DonationsMenuItem from "./menu_items/DonationsMenuItem";
import AboutMenuItem from "./menu_items/AboutMenuItem";
import ChildrenMenuItem from "./menu_items/ChildrenMenuItem";
import ContactMenuItem from "./menu_items/ContactMenuItem";
import HomeMenuItem from "./menu_items/HomeMenuItem";

import "./MainMenu.css"
import MainMobileMenu from "./MainMobileMenu";

const MainMenu = () => {
    return (
        <nav className="main-navigation navbar navbar-expand-lg" id="main-navigation">
            <button type="button"
                    className="menu-toggle toggle-mobile-menu"
                    data-bs-toggle="collapse"
                    data-bs-target="#mobileNav"
                    aria-controls="mobileNav"
                    aria-expanded="false">
                <i className="fa fa-bars" /> Menu
            </button>

            <MainMobileMenu />

            <ul className="menu exo-menu">
                <HomeMenuItem />
                <WorshipMenuItem />
                <CommunityMenuItem />
                <GroupsAndClubsMenuItem />
                <VenuesMenuItem />
                <DonationsMenuItem />
                <AboutMenuItem />
                <ChildrenMenuItem />
                <ContactMenuItem />
                <li className="menu-item social facebook">
                    <a href="https://www.facebook.com/StMichaelAllAngelsChurchEnfield" target="_blank" rel="noreferrer">
                        <i className="fab fa-facebook-f" />
                    </a>
                </li>
                <li className="menu-item social twitter">
                    <a href="https://twitter.com/stmichaelsgh" target="_blank" rel="noreferrer">
                        <i className="fab fa-twitter" />
                    </a>
                </li>
            </ul>
        </nav>
    );
}

export default MainMenu;
