import React from "react";
import "./TransportLinks.css"

const TransportLinks = () => {
    return (
        <section id="transport-links">
            <h3>By Public Transport</h3>
            <p>
                If you are taking public transport, there are several bus services which will bring you very close to the Church:
            </p>
            <p>
                The <a href="https://tfl.gov.uk/bus/route/191/" target="_blank" rel="noreferrer" className="bus-service">191</a> bus is the closest service to the Church (stop X/T).
            </p>
            <p>
                The <a href="https://tfl.gov.uk/bus/route/w9/" target="_blank" rel="noreferrer" className="bus-service">W9</a> and <a href="https://tfl.gov.uk/bus/route/w8/" target="_blank" rel="noreferrer" className="bus-service">W8</a> stop at either end of Chase Side and are a short walk away.
            </p>
            <p>
                The <a href="https://tfl.gov.uk/bus/route/313/" target="_blank" rel="noreferrer" className="bus-service">313</a>, <a href="https://tfl.gov.uk/bus/route/121/" target="_blank" rel="noreferrer" className="bus-service">121</a>, <a href="https://tfl.gov.uk/bus/route/231/" target="_blank" rel="noreferrer" className="bus-service">231</a>, <a href="https://tfl.gov.uk/bus/route/307/" target="_blank" rel="noreferrer" className="bus-service">307</a>, and <a href="https://tfl.gov.uk/bus/route/377/" target="_blank" rel="noreferrer" className="bus-service">377</a> services all stop in the wider Enfield Town area. (You may wish to hop on another bus to get you to the Church, unless you don't mind a bit of a walk!)
            </p>
        </section>
    );
}

export default TransportLinks;